<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        name="TicketList"
        ref="TicketListRef"
        title="Ticket"
        searchable
        :table-header="columns"
        :table-data="ticketList"
        :hasListActions="true"
        :context="context"
        :total="totalItems"
        localStorageKey="adminTicketColumns"
        @getList="getTicketList"
      >
        <template #listAction>
          <v-btn
            depressed
            class="text-capitalize white--text primary"
            @click="addTicket"
          >
            Add Ticket
          </v-btn>
        </template>
        <template #leftFilterSlot>
          <v-btn
            small
            depressed
            :color="Object.keys(filters).length == 0 ? 'background' : 'green'"
            class="ma-1 rounded-lg"
            @click="showTicketFilter = true"
          >
            <v-icon color="primary" size="20px"> mdi-filter</v-icon>
          </v-btn>
        </template>
        <template #rightSlot>
          <v-row no-gutters>
            <v-col cols="4" md="4" lg="4" xl="4">
              <BaseDatePickerInput
                v-model="filters.start_date"
                id="start_date"
                name="start_date"
                label="Start Date"
                clearable
                @input="setFilter('start_date', filters.start_date)"
              ></BaseDatePickerInput>
            </v-col>
            <v-col cols="4" md="4" lg="4" xl="4" class="pl-2">
              <BaseDatePickerInput
                v-model="filters.end_date"
                id="end_date"
                name="end_date"
                :min="filters.start_date"
                label="End Date"
                clearable
                @input="setFilter('end_date', filters.end_date)"
              ></BaseDatePickerInput>
            </v-col>
            <v-col cols="4" md="4" lg="4" xl="4" class="pl-2">
              <BaseSelect
                label="Project"
                :isClearable="true"
                :itemsList="projectList"
                item-text="name"
                item-value="id"
                v-model="filters.project"
                @change="setFilter('project', filters.project)"
              />
            </v-col>
          </v-row>
        </template>
        <template #dialogs>
          <TicketFilter
            v-model="showTicketFilter"
            @applyFilters="refreshList"
          ></TicketFilter>
          <TicketForm
            ref="TicketForm"
            v-model="showTicketForm"
            @updateList="updateList"
          />
          <AsssignUserModal
            ref="AssignUserForm"
            v-model="showAsignUserModal"
            :ticketData="ticketData"
            @updateList="updateList"
          />
        </template>
      </BaseListLayout>
    </v-col>
  </v-row>
</template>
  
  <script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import TicketForm from "./TicketForm.vue";
import AsssignUserModal from "./AssignUserModal.vue";
import ActionButton from "@/components/AgGridButtons/Ticket/ActionButton";
import PriorityButton from "@/components/AgGridButtons/Ticket/PriorityButton";
import ActualPriority from "@/components/AgGridButtons/Ticket/ActualPriority";
import StatusButton from "@/components/AgGridButtons/Ticket/StatusButton";
import TicketDesc from "@/components/AgGridButtons/Ticket/TicketDesc";
import TicketTitle from "@/components/AgGridButtons/Ticket/TicketTitle";
import TicketFilter from "./TicketFilter.vue";
import BaseDatePickerInput from "@/components/BaseComponents/BaseDatePickerInput.vue";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import { bus } from "@/main";
import TicketTypeChip from "@/components/AgGridButtons/Ticket/TicketTypeChip.vue";

export default {
  name: "Ticket",
  components: {
    BaseListLayout,
    TicketForm,
    AsssignUserModal,
    StatusButton,
    ActionButton,
    PriorityButton,
    ActualPriority,
    TicketFilter,
    BaseDatePickerInput,
    BaseSelect,
    TicketTitle,
    TicketDesc,
    TicketTypeChip,
  },
  data() {
    return {
      canEdit: true,
      totalItems: 0,
      filters: {},
      ticketData: null,
      ticketList: [],
      projectList: [],
      showAsignUserModal: false,
      showTicketForm: false,
      showTicketFilter: false,
      columns: [
        {
          headerName: "System Reference Number",
          field: "system_reference_number",
        },
        {
          headerName: "Ticket Reference Number",
          field: "ticket_reference_number",
        },
        { headerName: "Title", field: "title", cellRenderer: "TicketTitle" },
        { headerName: "Project", field: "project_name" },
        { headerName: "Added By", field: "added_by_fullname" },
        {
          headerName: "Status",
          field: "status",
          cellRenderer: "StatusButton",
          minWidth: 100,
        },
        { headerName: "Assignee", field: "assignee_name" },
        {
          headerName: "Ticket Type",
          field: "ticket_type",
          cellRenderer: "TicketTypeChip",
          minWidth: 150,
        },
        {
          headerName: "Actual Priority",
          field: "actual_priority",
          cellRenderer: "ActualPriority",
          minWidth: 100,
        },
        { headerName: "Created At", field: "created" },
        {
          headerName: "Actions",
          field: "actions",
          minWidth: 100,
          cellRenderer: "ActionButton",
        },
      ],
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getProjectList() {
      this.$api.project
        .getProjectList({ limit: "all" })
        .then((res) => {
          this.projectList = res.data;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    getTicketList(params) {
      bus.$emit("showLoader", true);
      let filters = localStorage.getItem("Ticket-Filters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      this.filters = filters;

      this.$api.ticket
        .getTicketList({ ...filters, ...params })
        .then((res) => {
          this.totalItems = res.count;
          this.ticketList = res.data.results;
          bus.$emit("showLoader", false);
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    setFilter(field, value) {
      let preFil = localStorage.getItem("Ticket-Filters");
      let selectedFilters = preFil ? JSON.parse(preFil) : {};
      if (field == "start_date") {
        value
          ? (selectedFilters.start_date = value)
          : delete selectedFilters.start_date;
      } else if (field == "end_date") {
        value
          ? (selectedFilters.end_date = value)
          : delete selectedFilters.end_date;
      } else if (field == "project") {
        value
          ? (selectedFilters.project = value)
          : delete selectedFilters.project;
      }

      localStorage.setItem("Ticket-Filters", JSON.stringify(selectedFilters));
      if (!Object.values(selectedFilters).length) {
        localStorage.removeItem("Ticket-Filters");
      }
      this.$refs.TicketListRef.refreshList();
    },
    refreshList() {
      this.$refs.TicketListRef.refreshList();
    },
    updateList() {
      this.$refs.TicketListRef.updateList();
    },
    addTicket() {
      this.editMode = false;
      this.showTicketForm = true;
    },
    viewDetails(id) {
      this.$router.push(`/admin/ticket-details?id=${id}`);
    },
    assignTicket(data) {
      this.showAsignUserModal = true;
      this.ticketData = data;
    },
  },
  mounted() {
    this.getProjectList();
  },
};
</script>
  
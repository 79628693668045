<template>
  <v-dialog v-bind="$attrs" v-model="openDialog">
    <v-card
      rounded="lg"
      elevation="2"
      :style="
        title == 'Add Client' || title == 'Edit Client' ? 'height: 100vh' : ''
      "
    >
      <v-card-title class="light_background">
        <span
          class="text-lg-subtitle-1 text-uppercase font-weight-bold black--text"
          v-if="title"
          >{{ title }}
        </span>
        <v-spacer></v-spacer>
        <v-icon @click="closeDialog()">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-4">
        <v-row class="ma-0">
          <v-col
            cols="12"
            v-if="nonFieldErrors && nonFieldErrors.length > 0"
            class="pt-0"
          >
            <v-alert dense type="error" class="ma-0">
              <v-list
                class="pa-0"
                dense
                style="background: inherit !important"
                v-for="(error, i) in nonFieldErrors"
                :key="i"
              >
                <v-list-item dense style="min-height: 20px !important">
                  <span>{{ i + 1 }} . </span>&nbsp;&nbsp;<span>{{
                    error
                  }}</span>
                </v-list-item>
              </v-list>
            </v-alert>
          </v-col>
          <v-col cols="12">
            <slot name="dialogContent" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-end light_background">
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable */
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Sample Form",
    },
    nonFieldErrors: {
      type: [Array, null],
      default: null,
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    closeDialog() {
      this.openDialog = false;
      this.$emit("closeDialog");
    },
  },
};
</script>

<style>
</style>
<!-- <template>
  <div class="pa-5">
    <v-row>
      <v-col cols="8" class="d-flex justify-start">
        <div class="ma-0 pa-0 py-2">
          <h2>Stats Center {{ showStats && title ? ": " + title : "" }}</h2>
        </div>
      </v-col>
      <v-col cols="4" class="ma-0 pa-0 d-flex justify-end align-center">
        <v-row class="ma-0 pa-0">
          <v-col cols="6">
            <div class="d-flex flex-column">
              <span class="text-caption black--text"> Select Date </span>
              <DateRangePicker
                v-model="dateRange"
                label="Select Date"
                class="date-picker"
                opens="left"
                @update="getStatistics(projectId)"
              >
                <template v-slot:input="picker" style="min-width: 100px">
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template></DateRangePicker
              >
            </div>
          </v-col>
          <v-col cols="6" class="pt-1">
            <div class="d-flex flex-column">
              <span> &nbsp;&nbsp </span>
              <v-select
                id="dashboard-filter-project"
                outlined
                v-model="project"
                :items="projectList"
                dense
                background-color="white"
                label="Select Project"
                item-text="code"
                item-value="id"
                @change="getStatistics($event)"
              >
              </v-select>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="showStats" class="ma-0 pt-0">
        <v-row class="">
          <v-col v-for="(stat, index) in statistics" :key="index">
            <v-card
              elevation="0"
              outlined
              class="cardOutline"
              style="height: 100%"
              :id="stat.widget_id"
            >
              <v-card-title class="py-1">
                <v-row>
                  <v-col
                    cols="12"
                    class="d-flex justify-space-between align-center pt-4"
                  >
                    <span class="text-body-2 font-weight-bold"
                      >{{ stat.title }}
                    </span>
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon medium v-bind="attrs" v-on="on" color="black">
                          mdi-information
                        </v-icon>
                      </template>
                      <span>{{ stat.help_text }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text
                class="ma-0 d-flex justify-center align-center"
                v-if="stat.statistics && stat.statistics.length > 0"
              >
                <v-row class="ma-0">
                  <v-col
                    cols="12"
                    v-for="(statistic, index) in stat.statistics"
                    :key="index"
                    class="ma-0 pa-0 d-flex"
                  >
                    <v-row class="ma-0 pa-0">
                      <v-col cols="6" class="ma-0 pa-0">
                        <span class="text-caption black--text">
                          {{ statistic.key }}
                        </span>
                      </v-col>
                      <v-col cols="6" class="d-flex justify-end ma-0 pa-0">
                        <span class="text-caption black--text">
                          {{ statistic.value }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text
                class="d-flex justify-center align-center"
                v-else
                style="height: 100%"
              >
                <v-row
                  class="d-flex justify-center align-top"
                  style="height: 100%"
                >
                  <v-col cols="12" class="d-flex justify-center pt-4">
                    <span class="text-h4 font-weight-bold">
                      {{ stat.value }}
                    </span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            v-for="(chart, index) in charts"
            :key="index"
            :cols="chart.cols"
          >
            <v-card
              class="charts-container cardOutline"
              elevation="0"
              outlined
              :cols="chart.cols"
              style="height: 100%"
            >
              <v-card-title class="py-2">
                <v-row>
                  <v-col cols="8">
                    <span class="text-h6 font-weight-bold black--text">
                      {{ chart.title }} Chart:
                    </span>
                  </v-col>
                  <v-col cols="4" class="d-flex justify-end">
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon medium v-bind="attrs" v-on="on" color="black">
                          mdi-information
                        </v-icon>
                      </template>
                      <span>{{ chart.help_text }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <component
                  v-if="getChartComponent(chart.type) != 'StackedBarChart'"
                  :is="getChartComponent(chart.type)"
                  :data="chart.data"
                  :height="chart.height"
                />
                <component
                  v-else
                  :is="getChartComponent(chart.type)"
                  :datasets="chart.data.datasets"
                  :labels="chart.data.labels"
                  :height="chart.height"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-else>
        <v-row>
          <v-col cols="12" class="d-flex justify-center align-center">
            <span class="text-h6 font-weight-bold">Please Select Project!</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template> -->
<template>
  <div class="pa-5">
    <v-row>
      <v-col cols="8" class="d-flex justify-start">
        <div class="ma-0 pa-0 py-2">
          <h2 class="title">
            Stats Center {{ showStats && title ? ": " + title : "" }}
          </h2>
        </div>
      </v-col>
      <v-col cols="4" class="ma-0 pa-0 d-flex justify-end align-center">
        <v-row class="ma-0 pa-0">
          <v-col cols="7" class="d-flex justify-end">
            <!-- <div class="pt-6 pr-5">
              <span>&nbsp;&nbsp</span>
              <v-btn class="primary" small>Export PDF</v-btn>
            </div> -->
            <div class="d-flex flex-column">
              <span class="text-caption text-secondary">Select Date</span>
              <DateRangePicker
                v-model="dateRange"
                label="Select Date"
                class="date-picker"
                opens="left"
                @update="getStatistics(projectId)"
              >
                <template v-slot:input="picker" style="min-width: 100px">
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template>
              </DateRangePicker>
            </div>
          </v-col>
          <v-col cols="5" class="pt-1 ma-0 pl-0">
            <div class="d-flex flex-column">
              <span>&nbsp;&nbsp</span>
              <v-select
                id="dashboard-filter-project"
                outlined
                v-model="project"
                :items="projectList"
                dense
                class="white--text"
                label="Select Project"
                background-color="white"
                item-text="name"
                item-value="id"
                @change="getStatistics($event)"
              >
              </v-select>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="ma-0 pt-0" v-if="showStats">
        <v-row>
          <v-col
            v-for="(stat, index) in statistics"
            :key="index"
            cols="12"
            sm="6"
            md="4"
            class="mb-4"
          >
            <v-card
              elevation="0"
              outlined
              class="card-outline bg-green"
              :id="stat.widget_id"
              color="1E88E5"
            >
              <!-- :color="`${stat.color}`" -->
              <v-card-title class="py-1">
                <v-row>
                  <v-col
                    cols="12"
                    class="d-flex justify-space-between align-center pt-4"
                  >
                    <span class="text-body-2 font-weight-bold">{{
                      stat.title
                    }}</span>
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon medium v-bind="attrs" v-on="on" color="black"
                          >mdi-information</v-icon
                        >
                      </template>
                      <span>{{ stat.help_text }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text
                class="ma-0 d-flex justify-center align-center"
                v-if="stat.statistics && stat.statistics.length > 0"
              >
                <v-row class="ma-0">
                  <v-col
                    cols="12"
                    v-for="(statistic, index) in stat.statistics"
                    :key="index"
                    class="ma-0 pa-0 d-flex"
                  >
                    <v-row class="ma-0 pa-0">
                      <v-col cols="6" class="ma-0 pa-0">
                        <span class="text-caption black--text">{{
                          statistic.key
                        }}</span>
                      </v-col>
                      <v-col cols="6" class="d-flex justify-end ma-0 pa-0">
                        <span class="text-caption black--text">{{
                          statistic.value
                        }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text
                class="d-flex justify-center align-center"
                v-else
                style="height: 100%"
              >
                <v-row
                  class="d-flex justify-center align-top"
                  style="height: 100%"
                >
                  <v-col cols="12" class="d-flex justify-center pt-4">
                    <span class="text-h4 font-weight-bold black--text">{{
                      stat.value
                    }}</span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="charts && charts.length > 0">
          <v-col
            v-for="(chart, index) in charts"
            :key="index"
            :cols="chart.cols"
          >
            <v-card
              v-if="refreshCharts"
              class="charts-container card-outline"
              elevation="0"
              outlined
              :cols="chart.cols"
              style="height: 100%"
            >
              <v-card-title class="py-2">
                <v-row>
                  <v-col cols="8">
                    <span class="text-h6 font-weight-bold black--text"
                      >{{ chart.title }} Chart</span
                    >
                  </v-col>
                  <v-col cols="4" class="d-flex justify-end">
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon medium v-bind="attrs" v-on="on" color="black"
                          >mdi-information</v-icon
                        >
                      </template>
                      <span>{{ chart.help_text }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text v-if="refreshCharts">
                <component
                  v-if="getChartComponent(chart.type) != 'StackedBarChart'"
                  :is="getChartComponent(chart.type)"
                  :data="chart.data"
                  :height="chart.height"
                  :options="chart.options"
                />
                <component
                  v-else
                  :is="getChartComponent(chart.type)"
                  :datasets="chart.data.datasets"
                  :labels="chart.data.labels"
                  :height="chart.height"
                  :options="chart.options"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-else>
        <v-row>
          <v-col cols="12" class="d-flex justify-center align-center">
            <span class="text-h6 font-weight-bold">Please Select Project!</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import LineChart from "@/components/Charts/LineChart.vue";
import HorizontalBarChart from "@/components/Charts/HorizontalBarChart.vue";
import BarChart from "@/components/Charts/BarChart.vue";
import PieChart from "@/components/Charts/PieChart.vue";
import StackedBarChart from "@/components/Charts/StackedBarChart.vue";
import TwoBarChart from "@/components/Charts/TwoBarChart.vue";
import DoughnutChart from "@/components/Charts/DoughnutChart.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { bus } from "@/main";

export default {
  components: {
    DateRangePicker,
    LineChart,
    HorizontalBarChart,
    BarChart,
    PieChart,
    StackedBarChart,
    TwoBarChart,
    DoughnutChart,
  },
  data() {
    return {
      project: null,
      projectList: [],
      showStats: false,
      dateRange: {
        startDate: null,
        endDate: null,
      },
      title: "",
      statistics: [],
      charts: [],
      refreshCharts: false,
    };
  },

  filters: {
    date(val) {
      if (!val) return "";

      const date = new Date(val);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
  },

  methods: {
    getRandomColor() {
      const colorList = [
        "#FF6384",
        "#36A2EB",
        "#FFCE56",
        "#4BC0C0",
        "#9966FF",
        "#FF9F40",
        "#FFCD56",
        "#C9CBCF",
      ];
      return colorList[Math.floor(Math.random() * colorList.length)];
    },
    filterRecentData(data) {
      const now = new Date();
      const thirtyDaysAgo = new Date(now.setDate(now.getDate() - 30));

      return data.filter((item) => {
        const itemDate = new Date(item.key || item.key);
        return itemDate >= thirtyDaysAgo;
      });
    },
    transformChartsData(rawCharts) {
      console.log("====", rawCharts);
      return rawCharts
        .filter((chart) => chart.data && chart.data.length > 0)
        .map((chart) => {
          if (chart.data.length > 30) {
            chart.data = chart.data.slice(0, 30);
          }
          const chartTypeMap = {
            line_chart: "line",
            bar_chart: "bar",
            horizontal_bar_chart: "horizontalBar",
            pie_chart: "pie",
            doughnut_chart: "doughnut",
            stacked_bar_chart: "stackedBar",
          };

          if (chart.type === "stacked_bar_chart") {
            console.log("inside stack", chart);
            const datasets = chart.data.map((item, index) => ({
              label: item.label,
              data: item.value,
              backgroundColor:
                chart.chart_info.color[index] || this.getRandomColor(),
              borderWidth: 1,
            }));
            console.log("dataSets", datasets);

            return {
              cols: chart.cols || "6",
              title: chart.title,
              type: "stackedBar",
              help_text: chart.help_text,
              height: chart.height || 150,
              data: {
                labels: chart.data.map((item) => item.key),
                backgroundColor: chart.chart_info.color,
                datasets: datasets,
              },
              options: {
                scales: {
                  xAxes: [
                    {
                      stacked: true,
                      barPercentage: 0.2,
                    },
                  ],
                  yAxes: [
                    {
                      stacked: true,
                      min: 0,
                    },
                  ],
                },
              },
            };
          } else {
            if (chartTypeMap[chart.type] == "line") {
              return {
                cols: chart.cols || "6",
                title: chart.title,
                type: chartTypeMap[chart.type] || "bar",
                help_text: chart.help_text,
                height: chart.height || 150,
                data: {
                  labels: chart.data.map((item) => item.key),
                  datasets: [
                    {
                      label: chart.chart_info.legend,
                      data: chart.data.map((item) => item.value),
                      fill: false,
                      borderColor:
                        chart.chart_info.color || this.getRandomColor(),
                      borderWidth: 1,
                    },
                  ],
                },
                options: {
                  scales: {
                    yAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "",
                        },
                        ticks: {
                          min: 0,
                        },
                      },
                    ],
                    xAxes: [
                      {
                        scaleLabel: {
                          display: true,
                        },
                      },
                    ],
                  },
                },
              };
            } else {
              return {
                cols: chart.cols || "6",
                title: chart.title,
                type: chartTypeMap[chart.type] || "bar",
                help_text: chart.help_text,
                height: chart.height || 150,
                data: {
                  labels: chart.data.map((item) => item.key),
                  datasets: [
                    {
                      label: chart.chart_info.legend,
                      data: chart.data.map((item) => item.value),
                      backgroundColor: chart.chart_info.color,
                    },
                  ],
                },
                options: {
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          min: 0,
                        },
                      },
                    ],
                  },
                },
              };
            }
          }
        });
    },
    setCurrentMonthRange() {
      const now = new Date();
      const start_date = new Date(now.getFullYear(), now.getMonth(), 1);
      const end_date = new Date(now.getFullYear(), now.getMonth() + 1, 0);

      this.dateRange.startDate = start_date;
      this.dateRange.endDate = end_date;
    },
    formatDate(date) {
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();

      return `${year}-${month}-${day}`;
    },
    getProjectList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      bus.$emit("showLoader", true);
      this.$api.project
        .getProjectList(params)
        .then((res) => {
          this.projectList = res.data;
          bus.$emit("showLoader", false);
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.log(err);
        });
    },
    getChartComponent(type) {
      switch (type) {
        case "bar":
          return "BarChart";
        case "doughnut":
          return "DoughnutChart";
        case "pie":
          return "PieChart";
        case "line":
          return "LineChart";
        case "horizontalBar":
          return "HorizontalBarChart";
        case "stackedBar":
          return "StackedBarChart";
        default:
          return "div";
      }
    },
    getChartProps(chart) {
      // Check the chart type and return props accordingly
      if (["bar", "stackedBar"].includes(chart.type)) {
        return {
          labels: chart.data.labels || [],
          datasets: chart.data.datasets || [],
          options: chart.options || {},
        };
      } else {
        return {
          data: chart.data || [],
          options: chart.options || {},
        };
      }
    },
    getStatistics(id, params = {}) {
      this.projectId = id;
      params = {
        ...params,
        start_date: this.formatDate(this.dateRange.startDate),
        end_date: this.formatDate(this.dateRange.endDate),
      };
      this.refreshCharts = false;
      bus.$emit("showLoader", true);
      this.$api.project
        .getStatistics(id, params)
        .then((res) => {
          // const sampleData = {
          //   title: "Perfetto || DASH",
          //   widgets: [
          //     {
          //       widget_id: "total_orders",
          //       type: "summary",
          //       title: "Total Orders",
          //       value: 1234,
          //       help_text: "The total number of orders placed.",
          //       color: "#ff0000",
          //     },
          //     {
          //       widget_id: "total_trips",
          //       type: "summary",
          //       title: "Total Trips",
          //       value: 567,
          //       help_text: "The total number of trips made.",
          //       color: "#00ff00",
          //     },
          //   ],
          //   charts: [
          //     {
          //       cols: "6",
          //       chart_id: "daily_orders",
          //       type: "stacked_bar_chart",
          //       title: "Branch Wise Orders",
          //       data: [
          //         {
          //           label: "Riyadh",
          //           key: "2023-01-01",
          //           value: [12, 23, 34],
          //         },
          //         {
          //           label: 'Jeddah',
          //           key: "2023-01-02",
          //           value: [98, 62, 77],
          //         },
          //         {
          //           label: 'Dammam',
          //           key: "2023-01-03",
          //           value: [66, 77, 88],
          //         },
          //       ],
          //       help_text: "The number of orders per branch.",
          //       chart_info: {
          //         x_axis: "key",
          //         y_axis: "value",
          //         legend: "Orders",
          //         color: ['#36A2EB', '#F16083', '#4BC0C0'],
          //       },
          //       height: 150,
          //     },
          //     {
          //       cols: "6",
          //       chart_id: "daily_trips",
          //       type: "bar_chart",
          //       title: "Daily Trips",
          //       data: [
          //         { key: "2023-01-01", value: 20 },
          //         { key: "2023-01-02", value: 20 },
          //       ],
          //       help_text: "The number of trips per day.",
          //       chart_info: {
          //         x_axis: "key",
          //         y_axis: "value",
          //         legend: "Trips",
          //         color: ["#ff00ff", "#0000ff"],
          //       },
          //       height: 150,
          //     },
          //   ],
          // };
          this.charts = this.transformChartsData(res?.data?.charts);
          this.title = res?.data?.title;
          this.statistics = res?.data?.widgets;
          bus.$emit("showLoader", false);
          this.refreshCharts = true;
          this.showStats = true;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          if (err?.data?.message) {
            this.showStats = err?.data?.message ? false : true;
            bus.$emit("showToastMessage", {
              message: err?.data?.message,
              color: "error",
            });
          }
          this.refreshCharts = true;
        });
    },
  },
  mounted() {
    this.getProjectList();
    this.setCurrentMonthRange();
  },
};
</script>

<style scoped>
.vue-daterange-picker {
  min-width: 50px;
}

.cardOutline.v-sheet.v-card {
  border-width: 1.5px;
  border-color: grey;
}
</style>

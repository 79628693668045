import { project } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getProjectList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(project.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getProjectObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${project.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  viewProjectObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${project.base}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addProject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(project.base, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  editProject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${project.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getProjectSupportUser(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${project.support_user}`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addUsersToProject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${project.support_user}`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  removeSupportUser(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${project.support_user}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addModuleToProject(id, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${project.base}${id}/modules/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getProjectModuleList(id, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${project.base}${id}/module_list/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addProjectEmailTemplate(id, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${project.base}${id}/email_template/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getStatistics(id, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${project.base}${id}/statistics/`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});

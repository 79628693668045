import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import api from "@/plugins/api.js";
import globalObject from "../src/plugins/globalConfig";
import VTiptap from "@peepi/vuetify-tiptap";

Vue.use(VTiptap);

import vuetify from "./plugins/vuetify";

export const bus = new Vue();

Vue.config.productionTip = false;

Vue.prototype.$api = api;
Vue.prototype.$globalConstant = globalObject;

new Vue({
  router,
  vuetify,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");

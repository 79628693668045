<template>
  <BaseDialog
    v-model="showUserFilter"
    title="Support User Filter"
    width="20%"
    @closeDialog="showUserFilter = false"
  >
    <template #dialogContent>
      <v-form ref="userFilter">
        <v-row no-gutters>
          <v-col cols="6" md="12" class="pl-2">
            <v-switch label="Active" v-model="filters.is_active"></v-switch>
          </v-col>
          <v-col cols="6" md="12" class="pl-2">
            <v-switch label="Admin" v-model="filters.is_admin"></v-switch>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #actions>
      <v-btn small class="rounded-lg" id="resetFilter" @click="resetFilters()">
        Reset
      </v-btn>
      <v-btn
        small
        class="rounded-lg primary"
        id="applyFilter"
        @click="applyFilters()"
      >
        Apply
      </v-btn>
    </template>
  </BaseDialog>
</template>
  
<script>
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";

export default {
  components: { BaseDialog },
  props: {
    title: String,
    value: Boolean,
  },
  data() {
    return {
      filters: {},
    };
  },
  watch: {
    showUserFilter(value) {
      if (value) {
        let filters = localStorage.getItem("User-Filters");
        if (!filters) {
          filters = {};
        }
        if (typeof filters == typeof "string") {
          filters = JSON.parse(filters);
        }
        this.filters = filters;
      }
    },
  },
  computed: {
    showUserFilter: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    applyFilters() {
      localStorage.setItem("User-Filters", JSON.stringify(this.filters));
      this.$emit("applyFilters");
      this.showUserFilter = false;
    },
    resetFilters() {
      this.filters = {};
      localStorage.removeItem("User-Filters");
      this.$emit("applyFilters");
      this.showUserFilter = false;
      if (this.$refs.userFilter) {
        this.$refs.userFilter.reset();
      }
    },
  },
};
</script>
  
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseAppBar',{staticClass:"white",scopedSlots:_vm._u([{key:"appBarLogo",fn:function(){return [_c(VImg,{staticStyle:{"width":"100px"},attrs:{"src":_vm.$globalConstant.logo}})]},proxy:true},{key:"userNotifications",fn:function(){return undefined},proxy:true},{key:"profileImage",fn:function(){return [_c(VAvatar,{attrs:{"color":"info","size":"35"}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v(" mdi-account-circle ")])],1)]},proxy:true},{key:"userName",fn:function(){return [_vm._v(" "+_vm._s(_vm.loggedUser && _vm.loggedUser.username ? _vm.loggedUser.username : "Default")+" ")]},proxy:true},{key:"listItemsMenu",fn:function(){return [_c(VCard,{staticClass:"user-card",attrs:{"elevation":"0"}},[_c(VCardTitle,{staticClass:"ma-0 pa-0"},[_c(VRow,{staticClass:"ma-0 pa-0"},[_c(VCol,{staticClass:"text-center",attrs:{"cols":"3"}},[_c(VAvatar,{attrs:{"color":"info","size":"30"}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-account-circle")])],1)],1),_c(VCol,{staticClass:"d-flex flex-column justify-start pl-0",attrs:{"cols":"6"}},[_c('h4',{staticClass:"black--text text-capitalize mb-1 text-body-1"},[_vm._v(" "+_vm._s(_vm.loggedUser.first_name)+" "+_vm._s(_vm.loggedUser.last_name)+" ")]),_c('h5',{staticClass:"black--text text-body-2 font-weight-medium text-capitalize"},[_vm._v(" "+_vm._s(_vm.loggedUser.is_admin ? "Admin" : _vm.loggedUser.type)+" ")])]),_c(VCol,{staticClass:"d-flex justify-end",attrs:{"cols":"3"}},[_c(VIcon,{on:{"click":function($event){return _vm.$emit('logout')}}},[_vm._v("mdi-logout")])],1)],1)],1),_c(VDivider),_c(VList,{attrs:{"dense":""}},[_c(VListItem,[_c(VListItemIcon,[_c(VIcon,{staticClass:"primary--text"},[_vm._v("mdi-account")])],1),_c(VListItemContent,{},[_c(VListItemTitle,{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.loggedUser.username))])],1)],1),_c(VListItem,[_c(VListItemIcon,[_c(VIcon,{class:{
                'green--text': _vm.loggedUser.is_active,
                'red--text': !_vm.loggedUser.is_active,
              }},[_vm._v(" "+_vm._s(_vm.loggedUser.is_active ? "mdi-check-circle" : "mdi-close-circle")+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.loggedUser.is_active ? "Active" : "Inactive"))])],1)],1),_c(VListItem,[_c(VListItemIcon,[_c(VIcon,{staticClass:"primary--text",attrs:{"small":""}},[_vm._v("mdi-phone")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.loggedUser.contact_number))])],1)],1),_c(VListItem,[_c(VListItemIcon,[_c(VIcon,{staticClass:"primary--text",attrs:{"small":""}},[_vm._v("mdi-email")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.loggedUser.email))])],1)],1)],1),_c(VDivider)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
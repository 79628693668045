<template>
  <BaseDialog
    v-model="showTicketForm"
    title="Add Ticket"
    width="80vh"
    @closeDialog="resetForm(), (showTicketForm = false)"
  >
    <template #dialogContent>
      <v-form
        v-model="isValid"
        ref="TicketForm"
        id="TicketForm"
        name="TicketForm"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              dense
              hide-details="auto"
              label="Ticket Title*"
              :rules="[(val) => !!val || 'Ticket Title is required']"
              v-model="ticket.title"
              :error-messages="
                formErrors && formErrors.title ? formErrors.title : ''
              "
              @input="
                formErrors && formErrors.title ? delete formErrors.title : ''
              "
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-textarea
              outlined
              hide-details="auto"
              dense
              label="Ticket Description*"
              :rules="[(val) => !!val || 'Ticket Description is required']"
              :error-messages="
                formErrors && formErrors.description
                  ? formErrors.description
                  : ''
              "
              v-model="ticket.description"
              @input="
                formErrors && formErrors.description
                  ? delete formErrors.description
                  : ''
              "
            ></v-textarea>
          </v-col>

          <v-col cols="12">
            <BaseSelect
              label="Project*"
              :itemsList="projectList"
              :rules="[(val) => !!val || 'Project is required']"
              item-text="name"
              item-value="id"
              v-model="ticket.project"
              :error-messages="
                formErrors && formErrors.project ? formErrors.project : ''
              "
              @input="
                formErrors && formErrors.project
                  ? delete formErrors.project
                  : ''
              "
            />
          </v-col>

          <v-col cols="12">
            <v-file-input
              outlined
              show-size
              dense
              counter
              append-icon="mdi-paperclip"
              prepend-icon=""
              chips
              :rules="[(val) => !!val || 'Attachment is required']"
              label="Attachment*"
              v-model="ticket.attachment"
              :error-messages="
                formErrors && formErrors.attachment ? formErrors.attachment : ''
              "
              @input="
                formErrors && formErrors.attachment
                  ? delete formErrors.attachment
                  : ''
              "
            >
            </v-file-input>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions>
      <v-btn
        id="resetBtn"
        class="rounded-lg"
        small
        @click="$refs.TicketForm.reset()"
      >
        Reset
      </v-btn>
      <v-btn
        :disabled="!isValid"
        class="rounded-lg primary"
        small
        @click="submitForm()"
        >Submit</v-btn
      >
    </template>
  </BaseDialog>
</template>
  
  <script>
import { bus } from "@/main";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import { generatedFormData } from "@/utils/functions.js";

export default {
  components: { BaseDialog, BaseSelect },
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      formErrors: {
        non_field_errors: [],
      },
      isValid: true,
      ticket: {},
      projectList: [],
    };
  },
  watch: {
    showTicketForm(val) {
      if (val) {
        this.getProjectList();
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showTicketForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getProjectList() {
      this.$api.project
        .getProjectList({ limit: "all" })
        .then((res) => {
          this.projectList = res.data;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    submitForm() {
      bus.$emit("showLoader", true);
      let data = { ...this.ticket };
      this.$api.supportTicket
        .addTicket(generatedFormData(data))
        .then((res) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Ticket Created!",
            color: "success",
          });
          this.resetForm();
          this.showTicketForm = false;
          this.$emit("updateList");
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          this.formErrors = err.data;
        });
    },
    resetForm() {
      const form = this.$refs.TicketForm;
      if (form) {
        form.reset();
      }
    },
  },
};
</script>
  
  <style></style>
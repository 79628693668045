<template>
  <div class="login-main">
    <v-row style="width: 100% !important; height: 100vh">
      <v-col cols="12" class="d-flex justify-center align-center">
        <div class="py-12 pl-10 d-flex justify-center align-center">
          <v-form
            v-model="isValid"
            ref="loginForm"
            @submit.prevent="onSubmit()"
          >
            <v-card
              class="px-12 py-12 align-center"
              style="max-width: 450px; min-width: 350px"
            >
              <v-card-title class="d-flex justify-center">
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="pa-0 ma-0 d-flex justify-center">
                    <v-img
                      :src="$globalConstant.logo"
                      contain
                      style="height: 50%; width: 50%"
                    ></v-img>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-row class="justify-center">
                  <v-col cols="pt-8 pb-6 ma-0">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12">
                        <v-text-field
                          v-model.trim="userDetails.username"
                          prepend-inner-icon="mdi-account-circle"
                          label="Username"
                          :rules="[(v) => !!v || 'Username is required']"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-text-field
                          label="Password"
                          v-model="userDetails.password"
                          prepend-inner-icon="mdi-lock-outline"
                          :append-icon="oldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="oldPassword ? 'text' : 'password'"
                          :rules="[(v) => !!v || 'Password is required']"
                          @click:append="oldPassword = !oldPassword"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="primary"
                  class="mt-4"
                  block
                  type="submit"
                  :disabled="!isValid"
                  customClass="rounded-lg"
                >
                  Login
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { bus } from "@/main";
export default {
  name: "mainLogin",
  layout: "authentication",
  data() {
    return {
      username: "",
      password: "",
      oldPassword: false,
      loading: false,
      userDetails: {},
      formErrors: {},
      isValid: true,
      error: {
        username: null,
        password: null,
      },
    };
  },
  methods: {
    onSubmit() {
      bus.$emit("showLoader", true);
      this.$api.auth
        .login(this.userDetails)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.loading = false;
          localStorage.setItem("fero-support", JSON.stringify(res.data));
          let obj = { ...res.data.user };
          if (obj) {
            if (obj.is_admin) {
              this.$router.push({
                path: `/admin`,
              });
            } else if (!obj.is_admin && obj.type == "Project User") {
              this.$router.push({
                path: `/project-user`,
              });
            } else if (!obj.is_admin && obj.type == "Support User") {
              this.$router.push({
                path: `/support-user`,
              });
            }
          }
          bus.$emit("showToastMessage", {
            message: "Login Successful...",
            color: "success",
          });
        })
        .catch((res) => {
          bus.$emit("showLoader", false);
          if (res && res?.data?.non_field_errors) {
            bus.$emit("showToastMessage", {
              message: res?.data?.non_field_errors[0],
              color: "error",
            });
          }
        });
    },
    loginProjectUser(authToken){
      bus.$emit("showLoader", true);
      this.$api.auth
        .loginProjectUser({"token": authToken})
        .then((res) => {
          bus.$emit("showLoader", false);
          this.loading = false;
          localStorage.setItem("fero-support", JSON.stringify(res.data));
          let obj = { ...res.data.user };
          if (obj) {
            if (obj.is_admin) {
              this.$router.push({
                path: `/admin`,
              });
            } else if (!obj.is_admin && obj.type == "Project User") {
              this.$router.push({
                path: `/project-user`,
              });
            } else if (!obj.is_admin && obj.type == "Support User") {
              this.$router.push({
                path: `/support-user`,
              });
            }
          }
          bus.$emit("showToastMessage", {
            message: "Login Successful...",
            color: "success",
          });
        })
        .catch((res) => {
          bus.$emit("showLoader", false);
          if (res && res?.data?.non_field_errors) {
            bus.$emit("showToastMessage", {
              message: res?.data?.non_field_errors[0],
              color: "error",
            });
          }
        });
    }
  },
  mounted(){
    const authToken = this.$route.params.authToken;
    if(authToken){
      console.log(authToken);
      this.loginProjectUser(authToken);
    }
  }
};
</script>

<style lang="scss">
.login-main {
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .login-main {
    display: flex;
    align-items: flex-end;
    height: 100vh;
  }
}
input {
  background-color: white !important;
}

.login-bg-image {
  position: fixed;
  height: 100vh !important;
  width: 100vw !important;
}
</style>

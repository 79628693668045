<template>
  <BaseAppBar class="white">
    <template #appBarLogo>
      <v-img style="width: 100px" :src="$globalConstant.logo" />
    </template>
    <template #userNotifications>
      <!-- <BaseNotification /> -->
    </template>
    <template #profileImage>
      <!-- <v-avatar>
        <img :src="$globalConstant.profileImage" />
      </v-avatar> -->
      <v-avatar color="info" size="35">
        <v-icon dark> mdi-account-circle </v-icon>
      </v-avatar>
    </template>
    <template #userName>
      {{ loggedUser && loggedUser.username ? loggedUser.username : "Default" }}
    </template>
    <template #listItemsMenu>
      <v-card elevation="0" class="user-card">
        <v-card-title class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="3" class="text-center">
              <v-avatar color="info" size="30">
                <v-icon dark>mdi-account-circle</v-icon>
              </v-avatar>
            </v-col>
            <v-col cols="6" class="d-flex flex-column justify-start pl-0">
              <h4 class="black--text text-capitalize mb-1 text-body-1">
                {{ loggedUser.first_name }} {{ loggedUser.last_name }}
              </h4>
              <h5
                class="black--text text-body-2 font-weight-medium text-capitalize"
              >
                {{ loggedUser.is_admin ? "Admin" : loggedUser.type }}
              </h5>
            </v-col>
            <v-col cols="3" class="d-flex justify-end">
              <v-icon class="" @click="$emit('logout')">mdi-logout</v-icon>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-list dense>
          <v-list-item>
            <v-list-item-icon>
              <v-icon class="primary--text">mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="">
              <v-list-item-title class="text-body-2">{{
                loggedUser.username
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon
                :class="{
                  'green--text': loggedUser.is_active,
                  'red--text': !loggedUser.is_active,
                }"
              >
                {{
                  loggedUser.is_active ? "mdi-check-circle" : "mdi-close-circle"
                }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-body-2">{{
                loggedUser.is_active ? "Active" : "Inactive"
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon small class="primary--text">mdi-phone</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-body-2">{{
                loggedUser.contact_number
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon small class="primary--text">mdi-email</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-body-2">{{
                loggedUser.email
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
      </v-card>
      <!-- <v-card elevation="0" width="auto" min-width="300">
        <v-row no-gutters class="light_background">
          <v-col cols="4" class="text-center py-3 px-0">
            <v-avatar color="info" size="35">
              <v-icon dark> mdi-account-circle </v-icon>
            </v-avatar>
          </v-col>
          <v-col cols="8" class="d-flex flex-column justify-center">
            <h4
              class="primary--text text-capitalize d-flex justify-space-between"
            >
              <span>
                {{ loggedUser.first_name }}
                {{ loggedUser.last_name }}
              </span>
            </h4>
            <h4
              class="secondary--text text-body-2 font-weight-light text-capitalize"
            >
              {{ loggedUser.is_admin ? "Admin" : loggedUser.type }}
            </h4>
          </v-col>
        </v-row>
        <v-card-text class="px-8">
          <div class="d-flex my-2">
            <div>
              <v-icon small class="mr-4 primary--text">mdi-account</v-icon>
            </div>
            <div>
              <span class="text-caption">
                {{ loggedUser.username }}
              </span>
            </div>
          </div>
          <div class="d-flex my-2">
            <div>
              <v-icon small class="mr-4 green--text">
                {{
                  loggedUser.is_active ? "mdi-check-circle" : "mdi-close-circle"
                }}
              </v-icon>
            </div>
            <div>
              <span class="text-caption">
                {{ loggedUser.is_active ? "Active" : "Inactive" }}
              </span>
            </div>
          </div>
          <div class="d-flex my-2">
            <div>
              <v-icon small class="mr-4 primary--text">mdi-phone</v-icon>
            </div>
            <div>
              <span class="text-caption">
                {{ loggedUser.contact_number }}
              </span>
            </div>
          </div>

          <div class="d-flex my-2">
            <div>
              <v-icon small class="mr-4 primary--text">mdi-email</v-icon>
            </div>
            <div>
              <span class="text-caption"> {{ loggedUser.email }} </span>
            </div>
          </div>
        </v-card-text>
        <v-card-text class="d-flex justify-end light_background">
          <v-btn
            class="rounded-lg primary"
            depressed
            small
            @click="$emit('logout')"
          >
            <v-icon small color="">mdi-logout</v-icon>Logout
          </v-btn>
        </v-card-text>
      </v-card> -->

      <!-- <v-list>
        <v-list-item :rounded="true" @click="$emit('show-profile', loggedUser)">
          <v-icon color="primary">mdi-account</v-icon>
          <span class="pl-2 text-capitalize primary--text">profile</span>
        </v-list-item>
        <v-list-item :rounded="true" @click="$emit('logout')">
          <v-icon color="primary">mdi-logout</v-icon>
          <span class="pl-2 text-capitalize primary--text">Logout</span>
        </v-list-item>
      </v-list> -->
    </template>
  </BaseAppBar>
</template>

<script>
import BaseAppBar from "@/components/BaseComponents/BaseAppBar.vue";
import BaseNotification from "./BaseNotification.vue";
import { checkifLoggedIn, getUserDetailsLocalStorage } from "@/utils/functions";

export default {
  components: { BaseAppBar, BaseNotification },
  data() {
    return {
      loggedUser: {},
    };
  },
  methods: {},
  mounted() {
    let isLoggedIn = checkifLoggedIn();
    if (isLoggedIn) {
      let user = getUserDetailsLocalStorage();
      let obj = { ...user.user };
      this.loggedUser = obj;
      console.log("---", this.loggedUser);
    }
  },
};
</script>

<style scoped>
.user-card {
  width: 100%;
  min-width: 300px;
  max-width: 400px;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
}

.light-background {
  background-color: #f5f5f5;
}

.user-card .v-card-text {
  padding-top: 0;
}

.user-card .v-divider {
  margin: 0;
}

.user-card .v-btn {
  margin-top: 0.5rem;
}

.primary--text {
  color: #1976d2 !important;
}

.secondary--text {
  color: #757575 !important;
}

.green--text {
  color: #4caf50 !important;
}

.red--text {
  color: #f44336 !important;
}
</style>
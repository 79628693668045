<template>
  <v-row>
    <v-col>
      <v-icon v-if="params.data.is_admin" color="green"
        >mdi-checkbox-marked-circle</v-icon
      >
      <v-icon v-else color="red">mdi-close-circle</v-icon>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

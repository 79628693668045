import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VNavigationDrawer,{staticClass:"navigation-shadow",staticStyle:{"z-index":"11 !important"},attrs:{"permanent":"","width":"270","clipped":"","app":"","color":"white","mini-variant":"","expand-on-hover":""}},[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.menu),function(item,i){return _c(VListItem,{key:i,staticClass:"pa-0 ma-0"},[(item && item.items && item.items.length > 0)?_c(VListGroup,{staticClass:"w-100 v-list-sub-group black--text",scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItem,{attrs:{"router":"","exact":"","to":item.to}},[_c(VListItemAction,{staticClass:"mr-2"},[_c(VIcon,{staticClass:"text-h6 font-weight-light",attrs:{"color":"black"}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)]},proxy:true}],null,true)},_vm._l((item.items),function(subItem,index){return _c(VListItem,{key:index,staticClass:"v-list-sub-group",attrs:{"router":"","exact":"","to":subItem.to}},[(subItem.title == 'Updates By AWB')?_c(VListItemAction,{staticClass:"mr-2"},[_c('span',{staticClass:"text-h6 black--text rounded-lg text-caption"},[_vm._v(" AWB ")])]):_c(VListItemAction,{staticClass:"mr-2"},[_c(VIcon,{staticClass:"text-h6 font-weight-light",attrs:{"color":"black"}},[_vm._v(" "+_vm._s(subItem.icon)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(subItem.title)+" ")])],1)],1)}),1):_c(VListItem,{attrs:{"to":item.to,"router":"","exact":""}},[_c(VListItemAction,{staticClass:"mr-2"},[_c(VIcon,{staticClass:"text-h6 font-weight-light"},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-row>
    <v-col>
      <v-chip :color="color">
        <span class="text-capitalize">{{ params.data.priority }}</span>
      </v-chip>
    </v-col>
  </v-row>
</template>
  
  <script>
export default {
  computed: {
    color() {
      switch (this.params.data.priority) {
        case "medium":
          return "blue";
        case "high":
          return "green";
      }
    },
  },
};
</script>
  
<template>
  <BaseDialog
    v-model="showSupportUserForm"
    :title="`${editMode ? 'Edit' : 'Add'} Support User`"
    width="80vh"
    @closeDialog="resetForm(), (showSupportUserForm = false)"
  >
    <template #dialogContent>
      <v-form
        v-model="isValid"
        ref="SupportUserForm"
        id="SupportUserForm"
        name="SupportUserForm"
      >
        <v-row>
          <v-col cols="6">
            <v-text-field
              outlined
              dense
              hide-details="auto"
              label="First Name*"
              :rules="[(val) => !!val || 'First Name is required']"
              v-model="user.first_name"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              outlined
              hide-details="auto"
              dense
              label="Last Name*"
              :rules="[(val) => !!val || 'Last Name is required']"
              v-model="user.last_name"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              outlined
              hide-details="auto"
              dense
              label="User Name*"
              :rules="[(val) => !!val || 'Username is required']"
              v-model="user.username"
              :error-messages="
                formErrors && formErrors.username ? formErrors.username : ''
              "
              @input="
                formErrors && formErrors.username
                  ? delete formErrors.username
                  : ''
              "
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              outlined
              hide-details="auto"
              dense
              label="Email ID*"
              v-model="user.email"
              :rules="[(val) => !!val || 'Email is required']"
              :error-messages="
                formErrors && formErrors.email ? formErrors.email : ''
              "
              @input="
                formErrors && formErrors.email ? delete formErrors.email : ''
              "
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              type="password"
              outlined
              hide-details="auto"
              dense
              label="Password*"
              :rules="
                editMode
                  ? []
                  : [
                      (val) => !!val || 'Password is required',
                      (val) =>
                        (val && val.length >= 8) ||
                        'Password must be 8 character long',
                    ]
              "
              v-model="user.password"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              type="password"
              outlined
              hide-details="auto"
              dense
              label="Confirm Password*"
              :rules="
                editMode
                  ? []
                  : [
                      (val) => !!val || 'Confirm Password is required',
                      (val) =>
                        !user.password ||
                        val === user.password ||
                        'Password & Confirm Password must be same',
                    ]
              "
              v-model="user.confirm_password"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              outlined
              hide-details="auto"
              dense
              type="number"
              label="Contact Number*"
              :rules="[(val) => !!val || 'Contact Number is required']"
              v-model="user.contact_number"
              :error-messages="
                formErrors && formErrors.contact_number
                  ? formErrors.contact_number
                  : ''
              "
              @input="
                formErrors && formErrors.contact_number
                  ? delete formErrors.contact_number
                  : ''
              "
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions>
      <v-btn
        v-if="!editMode"
        id="resetBtn"
        class="rounded-lg"
        small
        @click="$refs.SupportUserForm.reset()"
      >
        Reset
      </v-btn>
      <v-btn
        :disabled="!isValid"
        id="userSubmitBtn"
        class="rounded-lg primary"
        small
        @click="submitForm()"
        >Submit</v-btn
      >
    </template>
  </BaseDialog>
</template>

<script>
import { bus } from "@/main";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";

export default {
  components: { BaseDialog },
  props: {
    value: {
      type: Boolean,
    },
    editMode: {
      type: Boolean,
    },
    supportUserID: {
      type: Number,
    },
  },
  data() {
    return {
      formErrors: {},
      isValid: true,
      user: {},
    };
  },
  watch: {
    showSupportUserForm(val) {
      if (val) {
        if (this.editMode && this.supportUserID) {
          this.getUserObject();
        }
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showSupportUserForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getUserObject() {
      bus.$emit("showLoader", true);
      this.$api.supportUser
        .getUserObject(this.supportUserID)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.user = res.data;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          this.formErrors = err.data;
        });
    },
    submitForm() {
      bus.$emit("showLoader", true);
      let data = {
        ...this.user,
        type: "Support User",
      };
      if (!this.editMode) {
        bus.$emit("showLoader", false);
        this.$api.supportUser
          .addUser(data)
          .then((res) => {
            bus.$emit("showToastMessage", {
              message: "User Created!",
              color: "success",
            });
            this.resetForm();
            this.showSupportUserForm = false;
            this.$emit("updateList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
          });
      } else {
        this.$api.supportUser
          .editUser({
            id: this.user.id,
            data: data,
          })
          .then(() => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "User details updated!",
              color: "success",
            });
            this.resetForm();
            this.showSupportUserForm = false;
            this.$emit("updateList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
          });
      }
    },
    resetForm() {
      const form = this.$refs.SupportUserForm;
      if (form) {
        form.reset();
      }
    },
  },
};
</script>

<style></style>

import axiosInstance from "./axios";

import auth from "@/services/auth";

//admin
import adminDashboard from "@/services/adminDashboard";
import supportUser from "@/services/supportUser";
import project from "@/services/project";
import ticket from "@/services/ticket";

//support user
import supportTicket from "@/services/supportTicket";
import projectTicket from "@/services/projectTicket";
import supportUserDashboard from "@/services/supportUserDashboard";

export default {
  auth: auth(axiosInstance),
  supportUser: supportUser(axiosInstance),
  project: project(axiosInstance),
  ticket: ticket(axiosInstance),
  adminDashboard: adminDashboard(axiosInstance),
  projectTicket: projectTicket(axiosInstance),
  supportTicket: supportTicket(axiosInstance),
  supportUserDashboard: supportUserDashboard(axiosInstance),
};

<template>
  <div class="custom-list">
    <v-row v-if="title || hasListActions" no-gutters class="ma-0">
      <v-col cols="12">
        <v-card
          elevation="0"
          style="background-color: #f5f8fa"
          class="pa-2 list-box-Shadow light_background"
        >
          <v-row no-gutters>
            <v-col cols="6" class="text-left d-flex align-center" v-if="title">
              <h3
                class="text-uppercase text-h6 black--text font-weight-bold"
              >
                {{ title }}
              </h3>
            </v-col>
            <v-col
              :cols="title ? '6' : '12'"
              class="d-flex justify-end"
              v-if="hasListActions"
            >
              <slot name="listAction" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters class="white pa-5 rounded-lg my-2 mx-2">
      <v-col cols="12">
        <v-row no-gutters>
          <v-col cols="3" class="d-flex app-search-input d-flex align-center">
            <v-text-field
              v-if="searchable"
              v-model.trim="search"
              prepend-inner-icon="mdi-magnify"
              outlined
              dense
              label="Search"
              clearable
              hide-details="auto"
              @click:clear="clearSearch"
            ></v-text-field>
            <div class="mr-2">
              <Columns
                v-if="!hideColumnSelection"
                :headers="tableHeader"
                :gridColInstance="gridColumnApi"
                :localStorageKey="localStorageKey"
              />
            </div>
            <slot name="leftFilterSlot" />
          </v-col>
          <v-col>
            <slot name="extraSpace"></slot>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-badge
              v-show="isState"
              class="ml-2 text-primary table-chip-index"
              top
              overlap
              color="primary"
              style="z-index: 2 !important"
            >
              <v-btn depressed color="background" @click="clearState" >
                <v-icon color="primary" size="25px">
                  mdi-table-column-width
                </v-icon>
              </v-btn>
            </v-badge>
            <slot name="rightSlot"> </slot>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="mt-5">
        <AgGridVue
          :style="gridStyle"
          class="ag-theme-alpine"
          :columnDefs="tableHeader"
          :default-col-def="defaultColDef"
          :context="{ ...context, ...listParentComponentContext }"
          :grid-options="hasOtherGridOptions ? otherGridOption : gridOptions"
          :rowData="tableData"
          v-on="$listeners"
          @selection-changed="onSelectionChanged"
          @grid-ready="onGridReady"
          :enableBrowserTooltips="true"
        >
        </AgGridVue>
      </v-col>
      <v-col v-if="total > 0" cols="12" class="py-0 pt-2">
        <v-row>
          <v-col cols="6" class="d-flex align-center justify-start">
            <v-chip label class="light_primary">
              <span class="text-body-2 text-uppercase primary--text">
                Total:
              </span>
              <span class="mx-1 font-weight-bold primary--text"
                >{{ total }}
              </span>
            </v-chip>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <BasePagination
              :pageNo="pageNo"
              :totalItems="total"
              :pageSize="itemsPerPage"
              @itemsPerPageChange="itemsPerPageChanged"
              @prevPage="prevPage"
              @nextPage="nextPage"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <slot name="dialogs" />
    <slot name="detail" />
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import { getAllUserPreferences, getUserPreferences } from "@/utils/functions";
import BasePagination from "@/components/BaseComponents/BasePagination.vue";
import Columns from "@/components/General/Columns.vue";

export default {
  emits: ["localStorageKey"],
  components: {
    AgGridVue,
    BasePagination,
    Columns,
  },
  props: {
    name: {
      required: true,
    },
    title: {
      required: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      required: true,
    },
    tableHeader: {
      type: Array,
      required: true,
    },
    context: {
      type: Object,
      default: () => {},
    },
    total: {
      type: [Number, String],
      default: 0,
    },
    initialCall: {
      type: Boolean,
      default: true,
    },
    otherGridOption: {
      type: Object,
    },
    hasOtherGridOptions: {
      type: Boolean,
      default: false,
    },
    setInterval: {
      type: Boolean,
      default: false,
    },
    setIntervalDuration: {
      type: Number,
      default: 1000,
    },
    gridStyle: {
      type: Object,
      default() {
        return {
          width: "100%",
          height: "calc(100vh - 335px)",
        };
      },
    },
    hideColumnSelection: {
      default: false,
    },
    hasListActions: {
      type: Boolean,
      default: true,
    },
    localStorageKey: {
      required: false,
    },
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: this.$globalConstant.itemsPerPage,
      pageNo: 1,
      filters: {},
      defaultColDef: {
        lockPosition: false,
        resizable: true,
        flex: 1
      },
      gridApi: null,
      gridColumnApi: null,
      gridOptions: {
        onGridSizeChanged: () => {
          if (this.previousState && this.previousState.length === 0) {
            setTimeout(() => {
              this.gridOptions.api.sizeColumnsToFit();
            }, 100);
          }
        },
        headerHeight: 40,
        rowHeight: 40,
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        isRowSelectable: false,
        enableCellTextSelection: true,
        alwaysShowHorizontalScroll: true,

        onColumnResized: this.onColumnChanged,
        onColumnMoved: this.onColumnChanged,

        suppressDragLeaveHidesColumns: true,
      },
      search: null,
      selectedRows: [],
      timeInterval: null,
      paginationTimeout: null,
      timeOut: 0,
      timeoutFn: null,
      isState: false,
    };
  },
  watch: {
    search(val) {
      if (val == "") {
        this.clearSearch();
      } else {
        if (this.timeOut) {
          clearTimeout(this.timeOut);
        }
        this.timeOut = setTimeout(() => {
          this.itemsPerPageChanged(this.itemsPerPage);
        }, 500);
      }
    },

    tableHeader: {
      handler: function (val, oldVal) {
        let nVal = val.map((v) => v.field);
        let oVal = oldVal.map((v) => v.field);

        let isChanged = !nVal
          .map((v, index) => v && oVal && oVal[index] === nVal[index])
          .every((v) => v === true);

        console.log(isChanged);
        if (isChanged) {
          this.checkingState();
          this.getUpdateState();
        }
      },
      deep: true,
    },
  },
  computed: {
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
    listParentComponentContext() {
      return { listParentComponentContext: this };
    },
    previousState() {
      return this.localStorageKey
        ? getUserPreferences(this.localStorageKey)
        : null;
    },
  },
  methods: {
    checkingState() {
      let preState = getUserPreferences(this.localStorageKey);
      this.isState =
        this.localStorageKey && preState && preState.length > 0 ? true : false;
    },
    getUpdateState() {
      if (this.gridColumnApi && this.localStorageKey) {
        setTimeout(() => {
          this.gridColumnApi.applyColumnState({
            state: getUserPreferences(this.localStorageKey),
          });
        }, 150);
      }
    },
    clearState() {
      if (this.localStorageKey && this.gridColumnApi) {
        let allColumnState = getAllUserPreferences();
        allColumnState[this.localStorageKey] = [];
        localStorage.setItem("userPreferences", JSON.stringify(allColumnState));
        this.resizeGrid();
        this.gridColumnApi.resetColumnState();
        this.checkingState();
      }
    },
    onColumnChanged(params) {
      if (this.timeoutFn) {
        clearTimeout(this.timeoutFn);
      }
      this.timeoutFn = setTimeout(() => {
        if (
          this.localStorageKey &&
          params &&
          (params.source === "uiColumnResized" ||
            params.source === "uiColumnMoved")
        ) {
          let allColumnState = getAllUserPreferences();
          allColumnState[this.localStorageKey] =
            this.gridColumnApi.getColumnState();
          localStorage.setItem(
            "userPreferences",
            JSON.stringify(allColumnState)
          );
          this.checkingState();
        }
      }, 50);
    },
    resizeGrid() {
      if (this.gridApi) {
        setTimeout(() => {
          this.gridApi.sizeColumnsToFit();
        }, 100);
      }
    },
    clearSearch() {
      this.search = null;
      this.itemsPerPageChanged(this.itemsPerPage);
    },
    // autoSizeAll() {
    //   let allColumnIds = [];
    //   this.gridOptions.columnApi.getColumns().forEach(function (column) {
    //     allColumnIds.push(column.colId);
    //   });
    //   this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
    // },
    onSelectionChanged() {
      this.selectedRows = this.gridApi.getSelectedRows();
      this.$emit("selectionChanged", this.selectedRows);
    },

    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }
      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(this.itemsPerPage);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      if (this.search) {
        this.filters.search = this.search.trim();
      } else {
        delete this.filters.search;
      }

      this.$emit("getList", {
        ...this.filters,
        offset: this.offset,
        limit: this.itemsPerPage,
      });
    },
    prevPage() {
      this.pageNo--;
      if (this.paginationTimeout) {
        clearTimeout(this.paginationTimeout);
      }
      this.paginationTimeout = setTimeout(() => {
        this.$emit("getList", {
          ...this.filters,
          offset: this.offset,
          limit: this.itemsPerPage,
        });
      }, 100);
    },
    nextPage() {
      this.pageNo++;
      if (this.paginationTimeout) {
        clearTimeout(this.paginationTimeout);
      }
      this.paginationTimeout = setTimeout(() => {
        this.$emit("getList", {
          ...this.filters,
          offset: this.offset,
          limit: this.itemsPerPage,
        });
      }, 100);
    },
    // autoSizeAll() {
    //   if (!this.hasOtherGridOptions) {
    //     let allColumnIds = [];
    //     this.gridOptions.columnApi.getColumns().forEach(function (column) {
    //       allColumnIds.push(column.colId);
    //     });
    //     this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
    //   } else {
    //     let allColumnIds = [];
    //     this.otherGridOption.columnApi.getColumns().forEach(function (column) {
    //       allColumnIds.push(column.colId);
    //     });
    //     this.otherGridOption.columnApi.autoSizeColumns(allColumnIds);
    //   }
    // },
    refreshList() {
      this.pageNo = 1;
      this.$emit("getList", {
        ...this.filters,
        offset: this.offset,
        limit: this.itemsPerPage,
      });
    },
    updateList() {
      this.$emit("getList", {
        ...this.filters,
        offset: this.offset,
        limit: this.itemsPerPage,
      });
    },
  },
  mounted() {
    this.checkingState();
    setTimeout(() => {
      this.getUpdateState();
    }, 50);
    if (this.initialCall) {
      this.$emit("getList", {
        ...this.filters,
        offset: this.offset,
        limit: this.itemsPerPage,
      });
    }
    if (this.setInterval) {
      this.timeInterval = setInterval(() => {
        this.$emit("getList", {
          ...this.filters,
          offset: this.offset,
          limit: this.itemsPerPage,
        });
      }, this.setIntervalDuration);
    }
  },
  beforeDestroy() {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }
  },
};
</script>
<style scoped>
</style>

import { supportUserDashboard } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getSupportUserDashboardStatistics() {
    return new Promise((resolve, reject) => {
      axios
        .get(supportUserDashboard.dashboardStatistics)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getStatusWiseTicketChartData() {
    return new Promise((resolve, reject) => {
      axios
        .get(supportUserDashboard.statusWiseTickets)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getProjectWiseActiveTicketChartData() {
    return new Promise((resolve, reject) => {
      axios
        .get(supportUserDashboard.projectWiseActiveTickets)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getLatestUpdatedTicketStatistics() {
    return new Promise((resolve, reject) => {
      axios
        .get(supportUserDashboard.latestUpdatedTickets)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getHighestInteractedOpenTicketStatistics() {
    return new Promise((resolve, reject) => {
      axios
        .get(supportUserDashboard.highestInteractedOpenTickets)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getProjectWiseActiveAndClosedTicketChartData(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(supportUserDashboard.projectWiseCreatedAndClosedTickets, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});

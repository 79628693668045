<template>
  <v-row>
    <v-col>
      <v-chip v-if="params.data.actual_priority" :color="color" small>
        <span class="text-capitalize font-weight-bold text-white">{{
          params.data.actual_priority
        }}</span>
      </v-chip>
    </v-col>
  </v-row>
</template>
  
  <script>
export default {
  computed: {
    color() {
      switch (this.params.data.actual_priority) {
        case "medium":
          return "#2196F3";
        case "high":
          return "error";
        case "low":
          return "#FF9800";
      }
    },
  },
};
</script>
  
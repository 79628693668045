<template>
  <div>
    <v-row class="ma-0 pa-0">
      <v-col class="col-auto">
        <v-btn class="rounded-lg" fab depressed small @click="$router.back()">
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <v-card>
          <v-tabs v-model="tab" color="deep-purple-accent-4" fixed-tabs>
            <v-tab key="email_template">Email Template</v-tab>
            <v-tab key="modules">Modules</v-tab>
            <v-tab key="support_user">Support User</v-tab>
          </v-tabs>
        </v-card>
        <v-window v-model="tab">
          <v-window-item key="email_template">
            <v-card
              v-for="(email, inx) in project_emails"
              :key="inx"
              width="50%"
              rounded="lg"
              class="mt-4"
              elevation="2"
            >
              <v-card-title class="black">
                <span class="white--text"> New Message </span>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text class="my-0 pa-0">
                <v-text-field
                  single-line
                  :rules="[(val) => !!val || 'Name is required']"
                  label="To"
                  v-model="email.name"
                ></v-text-field>
                <v-text-field
                  single-line
                  dense
                  :rules="[(val) => !!val || 'Subject is required']"
                  label="Subject"
                  v-model="email.subject"
                ></v-text-field>
                <v-tiptap v-model="email.body" />
              </v-card-text>
            </v-card>
            <v-btn
              small
              class="rounded-lg primary ma-4"
              @click="sendEmailTemplate()"
            >
              Update
            </v-btn>
          </v-window-item>
          <v-window-item key="modules">
            <v-form ref="ModuleForm" v-model="isValid">
              <v-card class="ma-2">
                <v-card-title>
                  <v-row>
                    <v-col cols="12">
                      <h5 class="text-body2 text-uppercase">Add Modules</h5>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text
                  class="pb-1 text-primary body-1"
                  v-for="(data, i) in modules"
                  :key="i"
                >
                  <v-row class="border pb-1 d-flex justify-space-between">
                    <v-col cols="10">
                      <v-text-field
                        outlined
                        dense
                        :rules="[(val) => !!val || 'Module Name is required']"
                        hide-details="auto"
                        label="Module Name*"
                        v-model="data.name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        class="rounded-lg green"
                        x-small
                        fab
                        depressed
                        @click="add()"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="i != 0"
                        class="rounded-lg red ml-1"
                        x-small
                        fab
                        depressed
                        @click="remove(i)"
                      >
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="d-flex justify-center">
                  <v-btn
                    :disabled="!isValid"
                    class="rounded-lg primary"
                    @click="addModuleToProject()"
                  >
                    Submit
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-window-item>
          <v-window-item key="support_user">
            <v-row class="ma-2">
              <v-col class="col-11">
                <BaseSelect
                  label="Select Support User"
                  :isClearable="true"
                  :itemsList="supportUserList"
                  item-text="username"
                  item-value="id"
                  v-model="selectedSupportUsers"
                  multiple
                  :item-disabled="isItemDisabled"
                />
              </v-col>
              <v-col>
                <v-btn
                  :disabled="selectedSupportUsers.length == 0"
                  class="rounded-lg primary"
                  @click="addUsersToProject()"
                >
                  Submit
                </v-btn>
              </v-col>
            </v-row>
            <v-card class="ma-2" v-if="supportUserData.length">
              <v-card-title>
                <v-row>
                  <v-col cols="12">
                    <h5 class="text-body2 text-uppercase">Support User</h5>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="pb-1 text-primary body-1">
                <v-row
                  v-for="item in supportUserData"
                  :key="item.id"
                  no-gutters
                  class="border pb-1 d-flex justify-space-between"
                >
                  <v-col cols="11" class="d-flex px-1 py-1">
                    {{ item.support_user_name }}
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                      x-small
                      fab
                      depressed
                      class="ma-1 rounded-lg"
                      @click="removeSupportUser(item.id)"
                      ><v-icon> mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import { bus } from "@/main";

export default {
  components: { BaseSelect },
  data() {
    return {
      isValid: true,
      tab: "email_template",
      supportUserList: [],
      modules: [{ project: this.$route.query.id }],
      moduleList: [{}],
      selectedSupportUsers: [],
      supportUserData: [],
      project_emails: [{ project: this.$route.query.id }],
    };
  },
  computed: {
    projectId() {
      return this.$route.query.id;
    },
  },
  methods: {
    add() {
      this.modules.push({ project: this.projectId });
    },
    remove(index) {
      this.modules.splice(index, 1);
    },
    viewProjectObject() {
      bus.$emit("showLoader", true);
      this.$api.project
        .viewProjectObject(this.projectId)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.modules = res?.data?.project_modules.length
            ? res?.data?.project_modules
            : this.modules;
          this.project_emails = res.data?.project_email_templates.length
            ? res.data.project_email_templates
            : this.project_emails;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    addModuleToProject() {
      bus.$emit("showLoader", true);
      this.$api.project
        .addModuleToProject(this.projectId, this.modules)
        .then((res) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Module Added Successfully",
            color: "success",
          });
          this.modules = [{ project: this.projectId }];
          this.viewProjectObject();
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    isItemDisabled(item) {
      const val = this.supportUserData.find(
        (data) => data.support_user == item.id
      );
      return val;
    },
    getProjectSupportUser() {
      bus.$emit("showLoader", true);
      this.$api.project
        .getProjectSupportUser({ project: this.projectId })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.supportUserData = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    getSupportUserList() {
      bus.$emit("showLoader", true);
      this.$api.supportUser
        .getSupportUserList({
          limit: "all",
          is_admin: false,
          type: "Support User",
        })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.supportUserList = res.data;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data,
            color: "red",
          });
        });
    },
    addUsersToProject() {
      bus.$emit("showLoader", true);
      let payload = [];
      this.selectedSupportUsers.forEach((user) => {
        payload.push({ support_user: user, project: this.projectId });
      });
      this.$api.project
        .addUsersToProject(payload)
        .then((res) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "User Added Successfully",
            color: "success",
          });
          this.getProjectSupportUser({ project: this.projectId });
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    removeSupportUser(id) {
      bus.$emit("showLoader", true);
      this.$api.project
        .removeSupportUser(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "User Removed Successfully",
            color: "success",
          });
          this.getProjectSupportUser({ project: this.projectId });
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    sendEmailTemplate() {
      bus.$emit("showLoader", true);
      this.$api.project
        .addProjectEmailTemplate(this.projectId, this.project_emails)
        .then((res) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Email Template Added Successfully",
            color: "success",
          });
          this.project_emails = [{ project: this.projectId }];
          this.viewProjectObject();
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
  },
  mounted() {
    this.getSupportUserList();
    this.getProjectSupportUser();
    this.viewProjectObject();
  },
};
</script>
<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { checkifLoggedIn, getUserDetailsLocalStorage } from "@/utils/functions";

export default {
  name: "DefaultLayout",
  methods: {
    async redirectIfLoggedIn() {
      let isLoggedIn = checkifLoggedIn();
      if (isLoggedIn) {
        let user = getUserDetailsLocalStorage();
        let obj = { ...user.user };

        if (obj) {
          if (obj.is_admin) {
            if (this.$route.fullPath.indexOf("/admin") == -1) {
              this.$router.push({
                path: `/admin`,
              });
            }
          } else if (!obj.is_admin && obj.type == "Project User") {
            if (this.$route.fullPath.indexOf("/project-user") == -1) {
              this.$router.push({
                path: `/project-user`,
              });
            }
          } else if (!obj.is_admin && obj.type == "Support User") {
            if (this.$route.fullPath.indexOf("/support-user") == -1) {
              this.$router.push({
                path: `/support-user`,
              });
            }
          }
        }
      } else {
        if (this.$route.fullPath.indexOf("/login") == -1) {
          this.$router.replace({ name: "login" });
        }
      }
    },
  },
  beforeMount() {
    this.redirectIfLoggedIn();
  },
};
</script>

<style></style>

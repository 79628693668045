import { router } from "@/router/index.js";
import axios from "axios";

function authHeader() {
  let user = localStorage.getItem("fero-support");
  if (user) {
    return JSON.parse(user).token;
  } else {
    return "";
  }
}

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

axiosInstance.interceptors.request.use((config) => {
  let head = authHeader();
  if (head) {
    config.headers.Authorization = `Token ${head}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (config) => {
    return config;
  },
  async (err) => {
    if (err.response.status == 401) {
      router.push("/login").catch((err) => {
        console.log("err", err);
      });
    }
    throw err;
  }
);

export default axiosInstance;

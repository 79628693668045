<template>
  <BaseDialog
    v-model="showProjectForm"
    :title="`${editMode ? 'Edit' : 'Add'} Project`"
    width="80vh"
    @closeDialog="resetForm(), (showProjectForm = false)"
  >
    <template #dialogContent>
      <v-form
        v-model="isValid"
        ref="ProjectForm"
        id="ProjectForm"
        name="ProjectForm"
      >
        <v-row>
          <v-col cols="6">
            <v-text-field
              outlined
              dense
              hide-details="auto"
              label="Project Code*"
              :rules="[(val) => !!val || 'Project Code is required']"
              v-model="project.code"
              :error-messages="
                formErrors && formErrors.code ? formErrors.code : ''
              "
              @input="
                formErrors && formErrors.code ? delete formErrors.code : ''
              "
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              outlined
              hide-details="auto"
              dense
              label="Project Name*"
              :rules="[(val) => !!val || 'Project Name is required']"
              v-model="project.name"
              :error-messages="
                formErrors && formErrors.name ? formErrors.name : ''
              "
              @input="
                formErrors && formErrors.name ? delete formErrors.name : ''
              "
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              outlined
              hide-details="auto"
              dense
              label="Email*"
              v-model="project.email"
              :rules="[(val) => !!val || 'Email is required']"
              :error-messages="
                formErrors && formErrors.email ? formErrors.email : ''
              "
              @input="
                formErrors && formErrors.email ? delete formErrors.email : ''
              "
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              outlined
              hide-details="auto"
              dense
              type="number"
              label="Contact Number*"
              :rules="[(val) => !!val || 'Contact Number is required']"
              v-model="project.contact_number"
              :error-messages="
                formErrors && formErrors.contact_number
                  ? formErrors.contact_number
                  : ''
              "
              @input="
                formErrors && formErrors.contact_number
                  ? delete formErrors.contact_number
                  : ''
              "
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              outlined
              hide-details="auto"
              dense
              label="Reference Prefix*"
              :rules="[(val) => !!val || 'Reference Prefix is required']"
              v-model="project.reference_prefix"
              :error-messages="
                formErrors && formErrors.reference_prefix
                  ? formErrors.reference_prefix
                  : ''
              "
              @input="
                formErrors && formErrors.reference_prefix
                  ? delete formErrors.reference_prefix
                  : ''
              "
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-switch
              class="pa-0"
              dense
              align="center"
              hide-details="auto"
              label="Active"
              v-model="project.is_active"
            ></v-switch>
          </v-col>
          <v-col cols="12" v-if="editMode">
            <v-text-field
              outlined
              hide-details="auto"
              dense
              readonly
              label="Api Key"
              v-model="project.api_key"
              :append-icon="copied ? 'mdi-check' : 'mdi-content-copy'"
              @click:append="copyApiValue(project.api_key)"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              hide-details="auto"
              dense
              label="Statistics URL*"
              v-model="project.statistics_url"
              :rules="[(val) => !!val || 'Statistics URL is required']"
              :error-messages="
                formErrors && formErrors.statistics_url ? formErrors.statistics_url : ''
              "
              @input="
                formErrors && formErrors.statistics_url
                  ? delete formErrors.statistics_url
                  : ''
              "
            >
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <v-alert :text="true" color="blue" dense>{{
              projectStatisticsUrl
            }}</v-alert>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions>
      <v-btn
        v-if="!editMode"
        id="resetBtn"
        class="rounded-lg"
        small
        @click="$refs.ProjectForm.reset()"
      >
        Reset
      </v-btn>
      <v-btn
        :disabled="!isValid"
        class="rounded-lg primary"
        small
        @click="submitForm()"
      >
        Submit
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import { bus } from "@/main";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";

export default {
  components: { BaseDialog },
  props: {
    value: {
      type: Boolean,
    },
    editMode: {
      type: Boolean,
    },
    projectId: {
      type: Number,
    },
  },
  data() {
    return {
      copied: false,
      formErrors: {},
      isValid: true,
      project: {
        is_active: false,
      },
    };
  },
  watch: {
    showProjectForm(val) {
      if (val) {
        if (this.editMode && this.projectId) {
          this.getProjectObject();
        }
      } else {
        this.resetForm();
      }
    },
    "project.statistics_url"(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateProjectStatisticsUrl(newVal);
      }
    },
  },
  computed: {
    showProjectForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    projectStatisticsUrl() {
      return this.editMode
        ? this.project.statistics_url
        : `${
            this.project.statistics_url ? this.project.statistics_url : "YOUR_DOMAIN"
          }`;
    },
  },
  methods: {
    getProjectObject() {
      this.$api.project
        .getProjectObject(this.projectId)
        .then((res) => {
          this.project = res.data;
        })
        .catch((err) => {
          this.formErrors = err.data;
        });
    },
    submitForm() {
      bus.$emit("showLoader", true);
      let data = {
        ...this.project,
        // statistics_url: `${this.project.statistics_url}`,
      };
      if (!this.editMode) {
        this.$api.project
          .addProject(data)
          .then((res) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Project Created!",
              color: "success",
            });
            this.resetForm();
            this.showProjectForm = false;
            this.$emit("updateList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
          });
      } else {
        this.$api.project
          .editProject({
            id: this.project.id,
            data: data,
          })
          .then(() => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Project details updated!",
              color: "success",
            });
            this.resetForm();
            this.showProjectForm = false;
            this.$emit("updateList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
          });
      }
    },
    resetForm() {
      const form = this.$refs.ProjectForm;
      if (form) {
        form.reset();
      }
      this.copied = false;
    },
    copyApiValue(value) {
      if (value) {
        navigator.clipboard.writeText(value);
        this.copied = true;
      }
    },
    updateProjectStatisticsUrl(newDomain) {
      // if (this.editMode) {
      //   this.projectStatisticsUrl = newDomain;
      // } else {
      //   this.projectStatisticsUrl = `${newDomain}`;
      // }
    },
  },
};
</script>

<style></style>

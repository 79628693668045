<template>
  <BaseDialog
    v-model="showAsignUserModal"
    title="Assign User"
    width="80vh"
    @closeDialog="resetForm(), (showAsignUserModal = false)"
  >
    <template #dialogContent>
      <v-form
        v-model="isValid"
        ref="AssignUserModal"
        id="AssignUserModal"
        name="AssignUserModal"
      >
        <v-row>
          <v-col cols="12">
            <BaseSelect
              label="Select User*"
              :itemsList="userList"
              :rules="[(val) => !!val || 'User is required']"
              item-text="support_user_full_name"
              item-value="support_user"
              v-model="assignee"
              :error-messages="
                formErrors && formErrors.assignee ? formErrors.assignee : ''
              "
              @input="
                formErrors && formErrors.assignee
                  ? delete formErrors.assignee
                  : ''
              "
            />
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions>
      <v-btn
        id="resetBtn"
        class="rounded-lg"
        small
        @click="$refs.AssignUserModal.reset()"
      >
        Reset
      </v-btn>
      <v-btn
        :disabled="!isValid"
        class="rounded-lg primary"
        small
        @click="submitForm()"
      >
        Assign
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import { bus } from "@/main";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";

export default {
  components: { BaseDialog, BaseSelect },
  props: {
    value: {
      type: Boolean,
    },
    ticketData: { type: Object },
  },
  data() {
    return {
      formErrors: {
        non_field_errors: [],
      },
      isValid: true,
      assignee: null,
      userList: [],
    };
  },
  watch: {
    showAsignUserModal(val) {
      if (val) {
        this.assignee = this.ticketData.assignee;
        this.getProjectSupportUser();
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showAsignUserModal: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getProjectSupportUser() {
      bus.$emit("showLoader", true);
      this.$api.project
        .getProjectSupportUser({ project: this.ticketData.project })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.userList = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data,
            color: "red",
          });
        });
    },
    submitForm() {
      let data = { assignee: this.assignee };
      this.$api.ticket
        .assignUserToTicket(this.ticketData.id, data)
        .then((res) => {
          bus.$emit("showToastMessage", {
            message: "User Assign Successfully!",
            color: "success",
          });
          this.resetForm();
          this.showAsignUserModal = false;
          this.$emit("updateList");
        })
        .catch((err) => {
          this.formErrors = err.data;
        });
    },
    resetForm() {
      const form = this.$refs.AssignUserModal;
      if (form) {
        form.reset();
      }
    },
  },
};
</script>

<style></style>

<template>
  <v-app>
    <BaseLeftNavigation />
    <BaseHeader @logout="logout" />
    <v-main style="position: relative" class="light_background">
      <v-container fluid class="pa-0">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import BaseHeader from "@/components/BaseComponents/BaseHeader.vue";
import BaseLeftNavigation from "@/components/BaseComponents/BaseLeftNavigation.vue";
import { checkifLoggedIn } from "@/utils/functions";

export default {
  name: "MainLayout",
  components: {
    BaseHeader,
    BaseLeftNavigation,
  },
  data() {
    return {
      loggedUser: {},
      showProfileModal: false,
    };
  },
  methods: {
    showProfile(user) {
      this.loggedUser = user;
      this.showProfileModal = true;
    },
    logout() {
      localStorage.clear();
      this.$router.push("/login");
    },
    checkIfLoggedIn() {
      if (!checkifLoggedIn) {
        if (this.$route.path !== "/login") {
          this.$router.push({ path: "/login" });
        }
      } else {
        if (this.$route.fullPath == "/") {
          this.$router.push({ path: "/" });
        }
      }
    },
  },
  beforeDestroy() {
    this.checkIfLoggedIn();
  },
};
</script>

<style>
.light_background {
  background-color: #f5f8fa;
}
</style>

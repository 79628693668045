<template>
  <v-row>
    <v-col>
      <v-chip
        color="grey"
        class="text-capitalize font-weight-bold text-white"
        small
        :color="color"
      >
        {{ formattedTicketType }}
      </v-chip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  computed: {
    formattedTicketType() {
      const ticketType = this.params.data.ticket_type.replace(/_/g, " ");
      return ticketType === "issue" ? "Problem/Issue" : ticketType;
    },
    color() {
      switch (this.params.data.ticket_type) {
        case "feedback":
          return "#FF9800";
        case "issue":
          return "#5B6E80";
        case "question":
          return "#9C27B0";
        case "feature_request":
          return "#2196F3";
        case "others":
          return "#4CAF50";
      }
    },
  },
};
</script>
  
<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        name="UserList"
        ref="SupportUserList"
        title="Support User"
        searchable
        :table-header="columns"
        :table-data="supportUserList"
        :hasListActions="true"
        :context="context"
        :total="totalItems"
        localStorageKey="userColumns"
        @getList="getSupportUserList"
      >
        <template #listAction>
          <v-btn
            depressed
            class="text-capitalize white--text primary"
            @click="addSupportUser"
          >
            Add Support User
          </v-btn>
        </template>
        <template #leftFilterSlot>
          <v-btn
            x-small
            fab
            depressed
            :color="Object.keys(filters).length == 0 ? 'background' : 'green'"
            class="ma-1 rounded-lg"
            @click="showUserFilter = true"
          >
            <v-icon color="primary" size="20px"> mdi-filter</v-icon>
          </v-btn>
        </template>
        <template #rightSlot></template>
        <template #dialogs>
          <UserFilter
            v-model="showUserFilter"
            @applyFilters="refreshList"
          ></UserFilter>
          <SupportUserForm
            ref="userForm"
            v-model="showSupportUserForm"
            :editMode="editMode"
            :supportUserID="supportUserID"
            @updateList="updateList"
          />
        </template>
      </BaseListLayout>
      <UserDetailDialog
        v-model="showUserDetailDialog"
        :userDetails="userDetails"
        @closeDetails="showUserDetailDialog = false"
      />
    </v-col>
  </v-row>
</template>
  
  <script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import SupportUserForm from "./SupportUserForm.vue";
import ActionsButton from "@/components/AgGridButtons/ActionsButton.vue";
import AdminButton from "@/components/AgGridButtons/AdminButton.vue";
import ActiveButton from "@/components/AgGridButtons/ActiveButton.vue";
import UserFilter from "./UserFilter.vue";
import UserDetailDialog from "./UserDetailDialog.vue";
import { bus } from "@/main";

export default {
  name: "SupportUser",
  components: {
    BaseListLayout,
    SupportUserForm,
    ActionsButton,
    ActiveButton,
    AdminButton,
    UserFilter,
    UserDetailDialog,
  },
  data() {
    return {
      supportUserID: null,
      totalItems: 0,
      supportUserList: [],
      filters: {},
      userDetails: {},
      canEdit: true,
      editMode: false,
      showUserFilter: false,
      showSupportUserForm: false,
      showUserDetailDialog: false,
      columns: [
        { headerName: "First Name", field: "first_name" },
        { headerName: "Last Name", field: "last_name" },
        { headerName: "Username", field: "username" },
        { headerName: "Email", field: "email" },
        { headerName: "Contact Number", field: "contact_number" },
        {
          headerName: "Active",
          field: "is_active",
          cellRenderer: "ActiveButton",
        },
        {
          headerName: "Admin",
          field: "is_admin",
          cellRenderer: "AdminButton",
        },
        { headerName: "Type", field: "type" },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "ActionsButton",
          minWidth: 100,
        },
      ],
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getSupportUserList(params) {
      bus.$emit("showLoader", true);
      let filters = localStorage.getItem("User-Filters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }

      if ("search" in filters) {
        filters.search = filters.search.toString();
      }
      this.filters = filters;

      this.$api.supportUser
        .getSupportUserList({ ...filters, ...params })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.supportUserList = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    refreshList() {
      this.$refs.SupportUserList.refreshList();
    },
    updateList() {
      this.$refs.SupportUserList.updateList();
    },
    addSupportUser() {
      this.editMode = false;
      this.showSupportUserForm = true;
    },
    editDetails(id) {
      this.editMode = true;
      this.supportUserID = id;
      this.showSupportUserForm = true;
    },
    viewDetails(id) {
      bus.$emit("showLoader", true);
      this.$api.supportUser
        .getUserObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.userDetails = res.data;
          this.showUserDetailDialog = true;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Error fetching data",
            color: "red",
          });
        });
    },
  },
};
</script>
  
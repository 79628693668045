<template>
  <v-row>
    <v-col>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            {{ params.data.description }}
          </span>
        </template>
        <span>{{ params.data.description }}</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>
  
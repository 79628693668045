<template>
  <BaseDialog
    v-model="showProjectFilter"
    title="Project Filter"
    width="30%"
    @closeDialog="showProjectFilter = false"
  >
    <template #dialogContent>
      <v-form ref="ProjectFilterForm">
        <v-row no-gutters>
          <v-col cols="12" md="12" class="pl-2 pt-2">
            <BaseDatePickerInput
              v-model="filters.start_date"
              id="start_date"
              name="start_date"
              label="Start Date"
              clearable
            ></BaseDatePickerInput>
          </v-col>
          <v-col cols="12" md="12" class="pl-2 pt-2">
            <BaseDatePickerInput
              v-model="filters.end_date"
              id="end_date"
              name="end_date"
              :min="filters.start_date"
              label="End Date"
              clearable
            ></BaseDatePickerInput>
          </v-col>
          <v-col cols="12" md="12" class="pl-2 pt-2">
            <BaseSelect
              label="Project"
              :isClearable="true"
              :itemsList="projectList"
              item-text="name"
              item-value="code"
              v-model="filters.code"
            />
          </v-col>
          <v-col cols="12" md="12" class="pl-2">
            <v-switch label="Active" v-model="filters.is_active"></v-switch>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #actions>
      <v-btn small class="rounded-lg" id="resetFilter" @click="resetFilters()">
        Reset
      </v-btn>
      <v-btn
        small
        class="rounded-lg primary"
        id="applyFilter"
        @click="applyFilters()"
      >
        Apply
      </v-btn>
    </template>
  </BaseDialog>
</template>
  
  <script>
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import BaseDatePickerInput from "@/components/BaseComponents/BaseDatePickerInput.vue";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";

export default {
  components: { BaseSelect, BaseDatePickerInput, BaseDialog },
  props: {
    title: String,
    value: Boolean,
  },
  data() {
    return {
      filters: {},
      projectList: [],
    };
  },
  watch: {
    showProjectFilter(value) {
      if (value) {
        this.getProjectList();
        let filters = localStorage.getItem("Support-Project-Filters");
        if (!filters) {
          filters = {};
        }
        if (typeof filters == typeof "string") {
          filters = JSON.parse(filters);
        }
        this.filters = filters;
      }
    },
  },
  computed: {
    showProjectFilter: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getProjectList() {
      this.$api.project
        .getProjectList({ limit: "all" })
        .then((res) => {
          this.projectList = res.data;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data,
            color: "red",
          });
        });
    },
    applyFilters() {
      localStorage.setItem(
        "Support-Project-Filters",
        JSON.stringify(this.filters)
      );
      this.$emit("applyFilters");
      this.showProjectFilter = false;
    },
    resetFilters() {
      this.filters = {};
      localStorage.removeItem("Support-Project-Filters");
      this.$emit("applyFilters");
      this.showProjectFilter = false;
      if (this.$refs.ProjectFilterForm) {
        this.$refs.ProjectFilterForm.reset();
      }
    },
  },
};
</script>
  
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDialog',{attrs:{"title":"Support User Filter","width":"20%"},on:{"closeDialog":function($event){_vm.showUserFilter = false}},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c(VForm,{ref:"userFilter"},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pl-2",attrs:{"cols":"6","md":"12"}},[_c(VSwitch,{attrs:{"label":"Active"},model:{value:(_vm.filters.is_active),callback:function ($$v) {_vm.$set(_vm.filters, "is_active", $$v)},expression:"filters.is_active"}})],1),_c(VCol,{staticClass:"pl-2",attrs:{"cols":"6","md":"12"}},[_c(VSwitch,{attrs:{"label":"Admin"},model:{value:(_vm.filters.is_admin),callback:function ($$v) {_vm.$set(_vm.filters, "is_admin", $$v)},expression:"filters.is_admin"}})],1)],1)],1)]},proxy:true},{key:"actions",fn:function(){return [_c(VBtn,{staticClass:"rounded-lg",attrs:{"small":"","id":"resetFilter"},on:{"click":function($event){return _vm.resetFilters()}}},[_vm._v(" Reset ")]),_c(VBtn,{staticClass:"rounded-lg primary",attrs:{"small":"","id":"applyFilter"},on:{"click":function($event){return _vm.applyFilters()}}},[_vm._v(" Apply ")])]},proxy:true}]),model:{value:(_vm.showUserFilter),callback:function ($$v) {_vm.showUserFilter=$$v},expression:"showUserFilter"}})
}
var staticRenderFns = []

export { render, staticRenderFns }
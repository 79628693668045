import { ticket } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getTicketList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(ticket.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTicketObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ticket.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addTicket(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(ticket.base, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  editTicket(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${ticket.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  assignUserToTicket(id, payload) {
    console.log("payload", payload);
    return new Promise((resolve, reject) => {
      axios
        .patch(`${ticket.base}${id}/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  sendComment(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ticket.ticket_comment}`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateStatus(id, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ticket.base}${id}/set_status/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updatePriority(id, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ticket.base}${id}/set_priority/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateDuedate(id, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ticket.base}${id}/set_due_date/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[(_vm.is_admin)?_c(VBtn,{staticClass:"rounded-lg mr-2",attrs:{"x-small":"","fab":"","depressed":""},on:{"click":function($event){return _vm.editDetails()}}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"text-h6"},'span',attrs,false),on),[_c(VIcon,{attrs:{"color":"green"}},[_vm._v("mdi-pencil")])],1)]}}],null,false,1957293021)},[_c('span',{staticClass:"text-capitalize"},[_vm._v("Edit Project Details")])])],1):_vm._e(),_c(VBtn,{staticClass:"rounded-lg mr-2",attrs:{"x-small":"","fab":"","depressed":""},on:{"click":function($event){return _vm.projectSettings()}}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"text-h6"},'span',attrs,false),on),[_c(VIcon,{attrs:{"color":"orange"}},[_vm._v("mdi-application-cog-outline")])],1)]}}])},[_c('span',{staticClass:"text-capitalize"},[_vm._v("Change Project Settings")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
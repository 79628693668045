import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,[_c(VCol,{staticClass:"d-flex justify-space-between pa-8",attrs:{"cols":"12"}},[_c('div',[_c('h3',{staticClass:"text-uppercase text-h6 text_color--text font-weight-bold"},[_vm._v(" Dashboard ")])])])],1),(_vm.dashboardStatistics && Object.keys(_vm.dashboardStatistics).length > 0)?_c(VRow,{attrs:{"no-gutters":"","justify":"space-between"}},[_c(VCol,{staticClass:"px-5 pt-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c(VRow,_vm._l((_vm.dashboardStatistics),function(value,key,index){return _c(VCol,{key:index,attrs:{"cols":"12","md":"2","sm":"2"}},[_c(VCard,{staticClass:"rounded-lg",attrs:{"flat":"","outlined":""}},[_c(VCard,{staticClass:"rounded-lg",attrs:{"flat":"","color":"primary"}},[_c(VCardText,{staticClass:"pa-3 white--text"},[_c(VRow,{staticClass:"ma-0"},[_c(VCol,{staticClass:"pb-0 text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-body-1 text-capitalize font-weight-bold"},[_vm._v(" "+_vm._s(key.replace(/_/g, " "))+" ")])])],1)],1)],1),_c(VCardText,{staticClass:"py-0 px-2"},[_c(VRow,{staticClass:"ma-0",attrs:{"justify":"space-around"}},[_c(VCol,{staticClass:"px-1",attrs:{"cols":"6"}},[_c('span',{staticClass:"text-body-3"},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.dashboardIcon[key])+" ")])],1)]),_c(VCol,{staticClass:"px-1",attrs:{"cols":"6"}},[_c('p',{staticClass:"text-h6 mb-0 primary--text font-weight-bold text-right"},[_vm._v(" "+_vm._s(value)+" ")])])],1)],1)],1)],1)}),1)],1)],1):_vm._e(),_c(VRow,{staticClass:"mt-2",attrs:{"no-gutters":"","justify":"space-between"}},[_c(VCol,{attrs:{"cols":"6"}},[_c(VRow,[_c(VCol,{staticClass:"pa-4 px-8",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Ticket Status")])]),(
            _vm.statusWiseTicketChartData &&
            Object.keys(_vm.statusWiseTicketChartData).length > 0
          )?_c(VCol,{staticClass:"px-8 pt-0",attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"rounded-lg",attrs:{"flat":"","outlined":"","min-height":"490"}},[_c(VCardTitle),_c(VCardText,[_c('BarChart',{key:_vm.statusWiseTicketChartsKey,attrs:{"data":_vm.statusWiseTicketChartData,"options":_vm.statusWiseTicketChartOptions}})],1)],1)],1)],1)],1):_vm._e()],1)],1),_c(VCol,{staticClass:"pa-3",attrs:{"cols":"6"}},[_c(VRow,[_c(VCol,{staticClass:"pa-1 px-5",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Project Wise Active Tickets ")])]),(
            _vm.projectWiseActiveTicketChartData &&
            Object.keys(_vm.projectWiseActiveTicketChartData).length > 0
          )?_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"rounded-lg",attrs:{"flat":"","outlined":"","min-height":"490"}},[_c(VCardTitle,[_c('span',{staticClass:"text-h6"},[_vm._v("Created vs Closed")])]),_c(VCardText,[_c('PieChart',{key:_vm.projectWiseActiveTicketChartKey,attrs:{"data":_vm.projectWiseActiveTicketChartData,"options":_vm.projectWiseActiveTicketChartOptions}})],1)],1)],1)],1)],1):_vm._e()],1)],1)],1),_c(VRow,{staticClass:"pa-4",attrs:{"no-gutters":"","justify":"space-between"}},[_c(VCol,{staticClass:"pa-1",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Project Wise Active And Closed Tickets ")])]),(_vm.projectWiseActiveAndClosedTickets)?_c(VCol,{staticClass:"pa-3",attrs:{"cols":"6"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"rounded-lg",attrs:{"flat":"","outlined":"","min-height":"400"}},[_c(VCardTitle,[_c(VCol,{staticClass:"pt-4 pa-5",attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"3"}},[_c('BaseDatePickerInput',{attrs:{"label":"Start Date","id":"start_date","name":"start_date","clearable":""},on:{"change":function($event){return _vm.getProjectWiseActiveAndClosedTicketGraph()}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c('BaseDatePickerInput',{attrs:{"label":"End Date","id":"start_date","name":"end_date","clearable":""},on:{"change":function($event){return _vm.getProjectWiseActiveAndClosedTicketGraph()}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)],1)],1),_c(VCardText,[_c('TwoBarChart',{attrs:{"labels":_vm.projectWiseActiveAndClosedTickets.data.labels,"datasets":_vm.projectWiseActiveAndClosedTickets.data.datasets,"options":_vm.projectWiseActiveAndClosedTickets.options,"height":250}})],1)],1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from "vue";
import VueRouter from "vue-router";

import { checkifLoggedIn, getUserDetailsLocalStorage } from "@/utils/functions";

//Layouts
import DefaultLayout from "../layouts/DefaultLayout.vue"; // Login
import AdminLayout from "../layouts/AdminLayout.vue"; // Admin
import SupportUserLayout from "../layouts/SupportUserLayout.vue"; // Support User
import ProjectUserLayout from "../layouts/ProjectUserLayout.vue"; // Support User

import Login from "../pages/Login";

//admin
import AdminDashboard from "../pages/AdminUser/Dashboard";
import AdminCenterDashboard from "../pages/AdminUser/Dashboard/StatsCenter";
import AdminSupportUser from "../pages/AdminUser/SupportUser";
import AdminProject from "../pages/AdminUser/Project";
import AdminProjectSetting from "../pages/AdminUser/Project/ProjectSetting";
import AdminTicket from "../pages/AdminUser/Ticket";
import AdminTicketDetails from "../pages/AdminUser/Ticket/TicketDetails";
//Project user
import ProjectDashboard from "../pages/ProjectUser/Dashboard";
import ProjectUserTicket from "../pages/ProjectUser/Ticket";
import ProjectUserTicketDetails from "../pages/ProjectUser/Ticket/TicketDetails";
//support user
import UserDashboard from "../pages/SupportUser/Dashboard";
import SupportUserProject from "../pages/SupportUser/Project";
import SupportUserProjectSetting from "../pages/SupportUser/Project/ProjectSetting";
import SupportUserTicket from "../pages/SupportUser/Ticket";
import SupportUserTicketDetails from "../pages/SupportUser/Ticket/TicketDetails";

Vue.use(VueRouter);

const AdminRoutes = [
  {
    path: "dashboard",
    name: "admin-dashboard",
    component: AdminDashboard,
  },
  {
    path: "statsCenter",
    name: "admin-center",
    component: AdminCenterDashboard,
  },
  {
    path: "supportuser",
    name: "admin-supportuser",
    component: AdminSupportUser,
  },
  {
    path: "project",
    name: "admin-projects",
    component: AdminProject,
  },

  {
    path: "project-settings",
    name: "admin-projects-setting",
    component: AdminProjectSetting,
    props: (route) => ({ id: route.query.id }),
  },
  {
    path: "ticket",
    name: "admin-ticket",
    component: AdminTicket,
  },
  {
    path: "ticket-details",
    name: "admin-ticket-details",
    component: AdminTicketDetails,
    props: (route) => ({ id: route.query.id }),
  },
];
const ProjectUserRoutes = [
  {
    path: "dashboard",
    name: "project-user-dashboard",
    component: ProjectDashboard,
  },
  {
    path: "ticket",
    name: "project-user-ticket",
    component: ProjectUserTicket,
  },
  {
    path: "ticket-details",
    name: "project-user-ticket-details",
    component: ProjectUserTicketDetails,
    props: (route) => ({ id: route.query.id }),
  },
];
const SupportUserRoutes = [
  {
    path: "dashboard",
    name: "support-user-dashboard",
    component: UserDashboard,
  },
  {
    path: "ticket",
    name: "support-user-ticket",
    component: SupportUserTicket,
  },
  {
    path: "ticket-details",
    name: "support-user-ticket-details",
    component: SupportUserTicketDetails,
    props: (route) => ({ id: route.query.id }),
  },
  {
    path: "project",
    name: "support-user-project",
    component: SupportUserProject,
  },
  {
    path: "project-settings",
    name: "support-user-project-settings",
    component: SupportUserProjectSetting,
    props: (route) => ({ id: route.query.id }),
  },
];

export const router = new VueRouter({
  routes: [
    {
      path: "/",
      component: DefaultLayout,
      redirect: (to) => {
        if (checkifLoggedIn()) {
          let user = getUserDetailsLocalStorage().user;
          if (user) {
            if (user.is_admin) {
              return { name: "admin" };
            } else if (!user.is_admin && user.type == "Support User") {
              return { name: "support-user" };
            }
          }
        } else {
          return { name: "login" };
        }
      },
      children: [
        {
          path: "login",
          name: "login",
          component: Login,
        },
        {
          path: "login/:authToken",
          name: "login",
          component: Login,
        },
        {
          path: "admin",
          component: AdminLayout,
          children: AdminRoutes,
          name: "admin",
          beforeEnter: (to, from, next) => {
            if (checkifLoggedIn()) next();
            else next({ name: "login" });
          },
          redirect: () => {
            return { name: "admin-dashboard" };
          },
        },
        {
          path: "project-user",
          name: "project-user",
          component: ProjectUserLayout,
          children: ProjectUserRoutes,
          beforeEnter: (to, from, next) => {
            if (checkifLoggedIn()) next();
            else next({ name: "login" });
          },
          redirect: (to) => {
            return { name: "project-user-ticket" };
          },
        },
        {
          path: "support-user",
          name: "support-user",
          component: SupportUserLayout,
          children: SupportUserRoutes,
          beforeEnter: (to, from, next) => {
            if (checkifLoggedIn()) next();
            else next({ name: "login" });
          },
          redirect: (to) => {
            return { name: "support-user-dashboard" };
          },
        },
      ],
    },
  ],
});

export default router;

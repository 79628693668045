import { adminDashboard } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getAdminDashboardStatistics(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(adminDashboard.dashboardStatistics, {params: params})
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getStatusWiseTicketChartData() {
    return new Promise((resolve, reject) => {
      axios
        .get(adminDashboard.statusWiseTickets)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getProjectWiseActiveTicketChartData() {
    return new Promise((resolve, reject) => {
      axios
        .get(adminDashboard.projectWiseActiveTickets)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getLatestUpdatedTicketStatistics() {
    return new Promise((resolve, reject) => {
      axios
        .get(adminDashboard.latestUpdatedTickets)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getHighestInteractedOpenTicketStatistics() {
    return new Promise((resolve, reject) => {
      axios
        .get(adminDashboard.highestInteractedOpenTickets)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getProjectWiseActiveAndClosedTicketChartData(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(adminDashboard.projectWiseCreatedAndClosedTickets, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});

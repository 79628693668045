import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{attrs:{"close-on-content-click":false,"nudge-width":100,"offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VTooltip,{attrs:{"bottom":"","max-width":"450"},scopedSlots:_vm._u([{key:"activator",fn:function(tooltipObj){return [_c(VBadge,{staticClass:"ml-2 text-primary table-chip-index",staticStyle:{"z-index":"2 !important"},attrs:{"top":"","overlap":"","content":_vm.visibleCols.length,"value":_vm.visibleCols.length,"color":"primary"}},[_c(VBtn,_vm._g(_vm._b({attrs:{"depressed":"","color":"background","small":""},on:{"click":function($event){_vm.rightDrawer = true}}},'v-btn',{ ...attrs, ...tooltipObj.attrs },false),{ ...on }),[_c(VIcon,{attrs:{"color":"primary","size":"20px"}},[_vm._v(" mdi-format-list-checks ")])],1)],1)]}}],null,true)})]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('div',{attrs:{"id":"col-select-item-list-container"}},[_c(VList,{attrs:{"dense":"","max-height":"350"}},_vm._l((_vm.allowedHeaders),function(header,i){return _c(VListItem,{key:i,staticClass:"white",attrs:{"selectable":false},on:{"click":function($event){return _vm.toggleSelection(header)}}},[_c(VListItemIcon,[(_vm.isSelected(header))?_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" mdi-checkbox-marked ")]):_c(VIcon,[_vm._v(" mdi-checkbox-blank-outline ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(header.headerName))])],1)],1)}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDialog',{attrs:{"title":"Add Ticket","width":"80vh"},on:{"closeDialog":function($event){_vm.resetForm(), (_vm.showTicketForm = false)}},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c(VForm,{ref:"TicketForm",attrs:{"id":"TicketForm","name":"TicketForm"},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"outlined":"","dense":"","hide-details":"auto","label":"Ticket Title*","rules":[(val) => !!val || 'Ticket Title is required'],"error-messages":_vm.formErrors && _vm.formErrors.title ? _vm.formErrors.title : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.title ? delete _vm.formErrors.title : ''}},model:{value:(_vm.ticket.title),callback:function ($$v) {_vm.$set(_vm.ticket, "title", $$v)},expression:"ticket.title"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Ticket Description*","rules":[(val) => !!val || 'Ticket Description is required'],"error-messages":_vm.formErrors && _vm.formErrors.description
                ? _vm.formErrors.description
                : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.description
                ? delete _vm.formErrors.description
                : ''}},model:{value:(_vm.ticket.description),callback:function ($$v) {_vm.$set(_vm.ticket, "description", $$v)},expression:"ticket.description"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VFileInput,{attrs:{"outlined":"","show-size":"","dense":"","counter":"","append-icon":"mdi-paperclip","prepend-icon":"","chips":"","rules":[(val) => !!val || 'Attachment is required'],"label":"Attachment*","error-messages":_vm.formErrors && _vm.formErrors.attachment ? _vm.formErrors.attachment : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.attachment
                ? delete _vm.formErrors.attachment
                : ''}},model:{value:(_vm.ticket.attachment),callback:function ($$v) {_vm.$set(_vm.ticket, "attachment", $$v)},expression:"ticket.attachment"}})],1)],1)],1)]},proxy:true},{key:"actions",fn:function(){return [_c(VBtn,{staticClass:"rounded-lg",attrs:{"id":"resetBtn","small":""},on:{"click":function($event){return _vm.$refs.TicketForm.reset()}}},[_vm._v(" Reset ")]),_c(VBtn,{staticClass:"rounded-lg primary",attrs:{"disabled":!_vm.isValid,"small":""},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("Submit")])]},proxy:true}]),model:{value:(_vm.showTicketForm),callback:function ($$v) {_vm.showTicketForm=$$v},expression:"showTicketForm"}})
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div style="background-color: #ececef">
    <v-row class="mx-0 d-flex align-center">
      <v-col cols="auto">
        <v-btn
          class="rounded-lg"
          style="background-color: #ececef"
          fab
          depressed
          small
          @click="$router.back()"
        >
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span class="text-h6" v-bind="attrs" v-on="on">
              {{ ticketDetails.ticket_reference_number }} -
            </span>
          </template>
          <span class="text-capitalize">Ticket Reference Number</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span class="text-h6" v-bind="attrs" v-on="on">
              {{ ticketDetails.project_name }}
            </span>
          </template>
          <span class="text-capitalize">Project Name</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <div class="px-3 pb-3 main-layout">
      <div class="project-column-grid pt-1">
        <div class="d-flex flex-column">
          <v-card class="mb-2" elevation="0">
            <v-card-title class="px-3 py-1">
              <v-row no-gutters>
                <v-col cols="8">
                  <h5>Basic Details</h5>
                </v-col>
                <v-col cols="2" class="d-flex justify-end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <h5 v-bind="attrs" v-on="on" class="text-caption">
                        <v-chip class="ml-2 font-weight-black" color="success">
                          {{ ticketDetails.status }}
                        </v-chip>
                      </h5>
                    </template>
                    <span class="text-capitalize">Status</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="2" class="text-right">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <h5 v-bind="attrs" v-on="on" class="text-caption">
                        <v-chip color="success">
                          <span class="text-capitalize font-weight-black">
                            {{ ticketDetails.priority }}
                          </span>
                        </v-chip>
                      </h5>
                    </template>
                    <span class="text-capitalize">Priority</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-subtitle
              style="height: calc(100vh - 600px)"
              class="overflow-y-auto custom-small-scroll"
            >
              <v-row no-gutters class="px-2">
                <v-col cols="12">
                  <h3>Title</h3>
                  <h4 class="font-weight-black primary--text">
                    {{ ticketDetails.title }}
                  </h4>
                </v-col>
                <v-col cols="12" class="pt-3">
                  <h3>Description</h3>
                  <h4 class="primary--text">
                    {{ ticketDetails.description }}
                  </h4>
                </v-col>
                <v-col cols="12" class="pt-3">
                  <h4>Added By</h4>
                  <h4 class="primary--text">
                    {{ ticketDetails.added_by_fullname }}
                  </h4>
                </v-col>
                <v-col cols="12" class="pt-3">
                  <h4>Due Date</h4>
                  <h4 class="primary--text">
                    {{ ticketDetails.due_date }}
                  </h4>
                </v-col>
              </v-row>
            </v-card-subtitle>
          </v-card>
        </div>
        <v-card class="mb-3 mx-2" elevation="0">
          <v-card-title class="px-3 py-1">
            <v-row no-gutters>
              <v-col cols="6">
                <h5>Comments</h5>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="d-flex flex-column">
            <div
              id="comment-target"
              class="overflow-y-auto custom-small-scroll"
              style="height: calc(100vh - 380px)"
            >
              <div>
                <div
                  v-for="(chat, index) in ticketDetails.ticket_comments"
                  :key="index"
                  class="d-flex py-2 px-8"
                  :class="!chat.set_right ? 'justify-start' : 'justify-end'"
                >
                  <div
                    class="w-80 pa-4 rounded-lg"
                    style="display: grid; grid-template-columns: 50px 1fr"
                  >
                    <div>
                      <v-btn
                        depressed
                        class="mr-1"
                        fab
                        dark
                        x-small
                        :color="!chat.set_right ? 'cyan' : 'warning'"
                      >
                        {{
                          chat.added_by_fullname
                            ? chat.added_by_fullname.slice(0, 2)
                            : ""
                        }}
                      </v-btn>
                    </div>
                    <div>
                      <div class="d-flex justify-space-between pr-2">
                        <h5 class="font-weight-black mr-2">
                          {{ chat.added_by_fullname }}
                        </h5>
                        <h5 class="font-weight-light">
                          {{ chat.created }}
                        </h5>
                      </div>
                      <div
                        class="d-flex flex-column text-right"
                        v-if="chat.attachment"
                      >
                        <a :href="chat.attachment" target="_blank"
                          >{{ chat.attachment }}
                        </a>
                      </div>
                      <div class="d-flex pt-2" v-else>
                        {{ chat.comment }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="px-4 pt-4">
              <v-row no-gutters>
                <v-col cols="1">
                  <v-btn
                    depressed
                    class="mr-1"
                    fab
                    dark
                    x-small
                    color="primary"
                  >
                    {{
                      ticketDetails.added_by_fullname
                        ? ticketDetails.added_by_fullname.slice(0, 2)
                        : ""
                    }}
                  </v-btn>
                </v-col>
                <v-col cols="11">
                  <v-form ref="commentForm" v-model="isValid">
                    <v-textarea
                      height="100"
                      outlined
                      hide-details="auto"
                      label="Comment here..."
                      v-model="ticket.comment"
                    ></v-textarea>
                  </v-form>
                </v-col>
                <v-col cols="12" class="text-right pt-3 pr-1">
                  <v-file-input
                    append-icon="mdi-paperclip"
                    prepend-icon=""
                    ref="fileUpload"
                    v-model="ticket.attachment"
                    label="File input"
                    class="mr-2 d-none"
                    outlined
                    dense
                  >
                  </v-file-input>
                  <v-icon
                    color="primary"
                    class="mr-4"
                    @click="$refs.fileUpload.$refs.input.click()"
                    >mdi-paperclip</v-icon
                  >
                  <v-btn
                    color="primary"
                    small
                    :disabled="
                      ticket.comment == null && ticket.attachment == null
                    "
                    depressed
                    @click="sendComment"
                  >
                    send
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>
  
  <script>
/* eslint-disable vue/no-unused-components */
import { bus } from "@/main.js";
import { generatedFormData } from "@/utils/functions.js";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import BaseDatePickerInput from "@/components/BaseComponents/BaseDatePickerInput.vue";

export default {
  components: { BaseSelect, BaseDatePickerInput },
  data() {
    return {
      isValid: true,
      ticketID: null,
      ticket: {},
      ticketDetails: {},
      status: null,
      actual_priority: null,
      due_date: null,
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getTicketObject(id) {
      bus.$emit("showLoader", true);
      this.$api.projectTicket
        .getTicketObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.ticketDetails = res.data;
          this.status = this.ticketDetails.status;
          this.actual_priority = this.ticketDetails.actual_priority;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.log(err);
        });
    },
    sendComment() {
      bus.$emit("showLoader", true);
      if (this.ticket.comment || this.ticket.attachment) {
        let payload = {
          ticket: this.ticketID,
          ...this.ticket,
        };
        this.$api.projectTicket
          .sendComment(generatedFormData(payload))
          .then((res) => {
            bus.$emit("showLoader", false);
            this.ticket = {};
            this.getTicketObject(this.ticketID);
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            console.log(err);
          });
      }
    },
  },
  mounted() {
    if ("id" in this.$route.query) {
      this.ticketID = this.$route.query.id;
      this.getTicketObject(this.ticketID);
    }
  },
};
</script>
  
  <style scoped>
.main-layout {
  background-color: #ececef;
  height: 100%;
}
.main-layout .project-column-grid {
  display: grid;
  grid-template-columns: 400px 1fr 0px;
  grid-column-gap: 8px;
  height: calc(100vh - 130px);
}

.card-vehicle-analytic-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 9px;
}
.w-80 {
  max-width: 80% !important;
  width: auto !important;
}
</style>
<template>
  <BaseDialog
    v-model="showTicketFilter"
    title="Ticket Filter"
    width="30%"
    @closeDialog="showTicketFilter = false"
  >
    <template #dialogContent>
      <v-form ref="TicketFilterForm">
        <v-row no-gutters>
          <v-col cols="12" md="6" lg="6" xl="6" class="pl-2 pt-2">
            <BaseDatePickerInput
              v-model="filters.start_date"
              id="start_date"
              name="start_date"
              label="Start Date"
              clearable
            ></BaseDatePickerInput>
          </v-col>
          <v-col cols="12" md="6" lg="6" xl="6" class="pl-2 pt-2">
            <BaseDatePickerInput
              v-model="filters.end_date"
              id="end_date"
              name="end_date"
              :min="filters.start_date"
              label="End Date"
              clearable
            ></BaseDatePickerInput>
          </v-col>
          <v-col cols="12" md="12" class="pl-2 pt-2">
            <BaseSelect
              label="Status"
              :isClearable="true"
              :itemsList="statusOption"
              v-model="filters.status"
            />
          </v-col>
          <v-col cols="12" md="12" class="pl-2 pt-2">
            <BaseSelect
              :isSearchRequired="false"
              label="Priority"
              :isClearable="true"
              :itemsList="priorityOption"
              item-text="label"
              item-value="value"
              v-model="filters.priority"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #actions>
      <v-btn small class="rounded-lg" id="resetFilter" @click="resetFilters()">
        Reset
      </v-btn>
      <v-btn
        small
        class="rounded-lg primary"
        id="applyFilter"
        @click="applyFilters()"
      >
        Apply
      </v-btn>
    </template>
  </BaseDialog>
</template>
  
  <script>
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import BaseDatePickerInput from "@/components/BaseComponents/BaseDatePickerInput.vue";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import { bus } from "@/main.js";

export default {
  components: { BaseSelect, BaseDatePickerInput, BaseDialog },
  props: {
    title: String,
    value: Boolean,
  },
  data() {
    return {
      filters: {},
      priorityOption: [
        { label: "Low", value: "low" },
        { label: "Medium", value: "medium" },
        { label: "High", value: "high" },
      ],
      statusOption: [
        "Open",
        "Acknowledged",
        "In Progress",
        "Resolved",
        "Reopened",
        "Closed",
      ],
    };
  },
  watch: {
    showTicketFilter(value) {
      if (value) {
        let filters = localStorage.getItem("Project-Ticket-Filters");
        if (!filters) {
          filters = {};
        }
        if (typeof filters == typeof "string") {
          filters = JSON.parse(filters);
        }
        this.filters = filters;
      }
    },
  },
  computed: {
    showTicketFilter: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    applyFilters() {
      localStorage.setItem(
        "Project-Ticket-Filters",
        JSON.stringify(this.filters)
      );
      this.$emit("applyFilters");
      this.showTicketFilter = false;
    },
    resetFilters() {
      this.filters = {};
      localStorage.removeItem("Project-Ticket-Filters");
      this.$emit("applyFilters");
      this.showTicketFilter = false;
      if (this.$refs.TicketFilterForm) {
        this.$refs.TicketFilterForm.reset();
      }
    },
  },
};
</script>
  
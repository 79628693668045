import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAppBar,{attrs:{"clipped-left":true,"clipped-right":true,"fixed":"","app":""}},[_c(VToolbarTitle,{staticClass:"d-flex"},[_vm._t("appBarLogo"),(_vm.title)?_c('h4',{class:_vm.customClass},[_vm._v(_vm._s(_vm.title))]):_vm._e()],2),_c(VSpacer),_c('div',{staticClass:"mr-6"},[_vm._t("userNotifications")],2),_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-width":150,"min-width":"100px","offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(VAvatar,{attrs:{"height":"90%"}},[_vm._t("profileImage")],2),_c('span',{staticClass:"primary--text font-weight-bold pl-3"},[_vm._t("userName")],2)],1)]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardText,{staticClass:"pa-0"},[_vm._t("listItemsMenu")],2)],1)],1),_vm._t("dialogs")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
export function handleResponse(res) {
  let obj = {};
  if (res.data && typeof res.data == typeof {}) {
    obj.data = res.data;
    if (res.data.data) {
      obj.data = res.data.data;
    }
    if (
      res.data.count != null ||
      res.data.count != undefined ||
      res.data.count != ""
    ) {
      obj.count = res.data.count;
    }
  } else {
    obj = res;
  }
  return obj;
}

export function handleError(err) {
  let obj = {};
  if (err.response && err.response.data) {
    obj.data = err.response.data;
    if (obj.data.error) {
      obj.data = err.response.data.error;
    }
  } else {
    obj.response = err.response;
  }
  // obj.status = err.response.status;
  return obj;
}

export function generatedFormData(obj, skipKeys = []) {
  let formData = new FormData();
  if (obj && typeof obj == "object") {
    Object.entries(obj).map(([key, value]) => {
      if (Array.isArray(value)) {
        value = value.join(",");
      }
      if (key == "coordinates") {
        value = JSON.stringify(value);
      }
      if (skipKeys.indexOf(key) == -1) {
        if (value != "" && value != null) {
          formData.append(key, value);
        }
      } else {
        formData.append(key, null);
      }
    });
  }
  return formData;
}

export function checkifLoggedIn() {
  return !!localStorage.getItem("fero-support");
}

export function getUserDetailsLocalStorage() {
  let userData = localStorage.getItem("fero-support");
  if (userData) {
    userData = JSON.parse(localStorage.getItem("fero-support"));
  }
  return userData;
}

export function getAllUserPreferences() {
  let preferences = localStorage.getItem("userPreferences");
  if (preferences) {
    return JSON.parse(preferences);
  }
  return {};
}

export function getUserPreferences(key) {
  let preferences = getAllUserPreferences();
  if (preferences && preferences[key]) {
    return preferences[key];
  }
  return [];
}

<template>
  <v-row>
    <v-col cols="12">
      <v-btn
        class="rounded-lg mr-2"
        x-small
        fab
        depressed
        @click="viewDetails()"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span class="text-h6" v-bind="attrs" v-on="on">
              <v-icon color="primary">mdi-eye</v-icon>
            </span>
          </template>
          <span class="text-capitalize">View Ticket Details</span>
        </v-tooltip>
      </v-btn>
      <v-btn
        v-if="is_admin"
        class="rounded-lg mr-2"
        x-small
        fab
        depressed
        @click="assignTicket()"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span class="text-h6" v-bind="attrs" v-on="on">
              <v-icon color="orange">mdi-account</v-icon>
            </span>
          </template>
          <span class="text-capitalize">Assign User</span>
        </v-tooltip>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { getUserDetailsLocalStorage } from "@/utils/functions";
export default {
  methods: {
    assignTicket() {
      this.params.context.parentComponent.assignTicket(this.params.data);
    },
    viewDetails() {
      this.params.context.parentComponent.viewDetails(this.params.data.id);
    },
  },
  computed: {
    is_admin() {
      return getUserDetailsLocalStorage().user.is_admin;
    },
  },
};
</script>

<style>
</style>
<template>
  <div>
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between pa-8">
        <div>
          <h3 class="text-uppercase text-h6 black--text font-weight-bold">
            Admin Dashboard
          </h3>
        </div>
        <div>
          <v-select
            id="dashboard-filter-project"
            outlined
            v-model="filters.project"
            :items="projectList"
            dense
            background-color="white"
            label="Select Project"
            item-text="name"
            item-value="id"
            @change="filterDashboardStatistics()"
          >
          </v-select>
        </div>
      </v-col>
    </v-row>

    <v-row
      no-gutters
      justify="space-between"
      v-if="dashboardStatistics && Object.keys(dashboardStatistics).length > 0"
    >
      <v-col cols="12" md="12" sm="12" class="px-5 pt-0">
        <v-row>
          <v-col
            cols="12"
            md="2"
            sm="2"
            v-for="(value, key, index) in dashboardStatistics"
            :key="index"
          >
            <v-card class="rounded-lg" flat outlined>
              <v-card flat color="grey lighten-3" class="rounded-lg">
                <v-card-text class="pa-3 black--text">
                  <v-row class="ma-0">
                    <v-col cols="12" class="pb-0 text-center">
                      <span
                        class="text-body-1 text-capitalize font-weight-bold"
                      >
                        {{ key.replace(/_/g, "  ") }}
                      </span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card-text class="py-0 px-2">
                <v-row class="ma-0" justify="space-around">
                  <v-col cols="6" class="px-1">
                    <span class="text-body-3">
                      <v-icon>
                        {{ dashboardIcon[key] }}
                      </v-icon>
                    </span>
                  </v-col>
                  <v-col cols="6" class="px-1">
                    <p
                      class="text-h6 mb-0 primary--text font-weight-bold text-right"
                    >
                      {{ value }}
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- Status Wise Tickets -->
    <v-row no-gutters justify="space-between" class="mt-2">
      <v-col cols="6">
        <v-row>
          <v-col cols="12" class="pa-4 px-8">
            <span class="text-h5 font-weight-bold">Ticket Status</span>
          </v-col>
          <v-col
            cols="12"
            class="px-8 pt-0"
            v-if="
              statusWiseTicketChartData &&
              Object.keys(statusWiseTicketChartData).length > 0
            "
          >
            <v-row>
              <v-col cols="12">
                <v-card flat outlined class="rounded-lg" min-height="490">
                  <v-card-title>
                    <!-- <span class="text-h6">Created vs Closed</span> -->
                  </v-card-title>
                  <v-card-text>
                    <BarChart
                      :data="statusWiseTicketChartData"
                      :options="statusWiseTicketChartOptions"
                      :key="statusWiseTicketChartsKey"
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <!-- Project Wise Active Ticket Chart  -->
      <v-col cols="6" class="pa-3">
        <v-row>
          <v-col cols="12" class="pa-1 px-5">
            <span class="text-h5 font-weight-bold"
              >Project Wise Active Tickets
            </span>
          </v-col>
          <v-col
            cols="12"
            v-if="
              projectWiseActiveTicketChartData &&
              Object.keys(projectWiseActiveTicketChartData).length > 0
            "
          >
            <v-row>
              <v-col cols="12">
                <v-card flat outlined class="rounded-lg" min-height="490">
                  <v-card-title>
                    <!-- <span class="text-h6">Created vs Closed</span> -->
                  </v-card-title>
                  <v-card-text>
                    <PieChart
                      :data="projectWiseActiveTicketChartData"
                      :options="projectWiseActiveTicketChartOptions"
                      :key="projectWiseActiveTicketChartKey"
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row no-gutters justify="space-between" class="pa-4">
      <v-col cols="12" class="pa-1">
        <span class="text-h5 font-weight-bold"
          >Project Wise Active And Closed Tickets
        </span>
      </v-col>
      <v-col cols="6" v-if="projectWiseActiveAndClosedTickets" class="pa-3">
        <v-row>
          <v-col cols="12">
            <v-card flat outlined class="rounded-lg" min-height="400">
              <v-card-title>
                <!-- <span class="text-h6">Acitve vs Closed</span> -->
                <v-col cols="12" class="pt-4 pa-5">
                  <v-row>
                    <v-col cols="3">
                      <BaseDatePickerInput
                        label="Start Date"
                        id="start_date"
                        name="start_date"
                        clearable
                        v-model="startDate"
                        @change="getProjectWiseActiveAndClosedTicketGraph()"
                      ></BaseDatePickerInput>
                    </v-col>
                    <v-col cols="3" class="">
                      <BaseDatePickerInput
                        label="End Date"
                        id="start_date"
                        name="end_date"
                        clearable
                        v-model="endDate"
                        @change="getProjectWiseActiveAndClosedTicketGraph()"
                      ></BaseDatePickerInput>
                    </v-col>
                  </v-row>
                </v-col>
              </v-card-title>
              <v-card-text>
                <TwoBarChart
                  :labels="projectWiseActiveAndClosedTickets.data.labels"
                  :datasets="projectWiseActiveAndClosedTickets.data.datasets"
                  :options="projectWiseActiveAndClosedTickets.options"
                  :height="270"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BarChart from "@/components/Charts/BarChart.vue";
import PieChart from "@/components/Charts/PieChart.vue";
import TwoBarChart from "@/components/Charts/TwoBarChart.vue";
import StackedBarChart from "@/components/Charts/StackedBarChart.vue";
import BaseDatePickerInput from "@/components/BaseComponents/BaseDatePickerInput.vue";
import { bus } from "@/main";

const defaultObj = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        stacked: true,
      },
    ],
    yAxes: [
      {
        stacked: true,
      },
    ],
  },
};

export default {
  name: "Dashboard",
  components: {
    BarChart,
    PieChart,
    TwoBarChart,
    StackedBarChart,
    BaseDatePickerInput,
  },

  data() {
    return {
      filters: {},
      projectList: [],
      dashboardStatistics: {},
      startDate: new Date().toISOString().slice(0, 10),
      endDate: new Date().toISOString().slice(0, 10),

      statusWiseTicketChartData: {},
      statusWiseTicketChartsKey: 0,
      statusWiseTicketChartOptions: {
        ...defaultObj,
      },

      projectWiseActiveTicketChartData: {},
      projectWiseActiveTicketChartKey: 0,
      projectWiseActiveTicketChartOptions: {
        ...defaultObj,
      },

      projectWiseActiveAndClosedTicketChartData: {},
      projectWiseActiveAndClosedTickets: null,
      projectWiseActiveAndClosedTicketChartKey: 0,
      projectWiseActiveAndClosedTicketChartOptions: {
        ...defaultObj,
      },

      dashboardIcon: {
        total_projects: "mdi-clipboard-text",
        total_support_users: "mdi-account-group",
        total_tickets: "mdi-ticket",
        average_ticket_time_in_days: "mdi-clock-outline",
      },
    };
  },
  methods: {
    filterDashboardStatistics() {
      this.getDashboardStatistics({ project: this.filters.project });
    },
    getProjectList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      bus.$emit("showLoader", true);
      this.$api.project
        .getProjectList(params)
        .then((res) => {
          this.projectList = res.data;
          bus.$emit("showLoader", false);
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.log(err);
        });
    },
    getDashboardStatistics(params = {}) {
      params = {
        ...params,
      };
      bus.$emit("showLoader", true);
      this.$api.adminDashboard
        .getAdminDashboardStatistics(params)
        .then((res) => {
          this.dashboardStatistics = res.data;
          delete this.dashboardStatistics["ticket_priority_wise_count"];
          bus.$emit("showLoader", false);
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.log(err);
        });
    },
    getStatusWiseTicketGraph() {
      bus.$emit("showLoader", true);
      this.$api.adminDashboard
        .getStatusWiseTicketChartData()
        .then((res) => {
          // let obj = res.data;
          // obj.datasets[1] = {
          //   ...obj.datasets[1],
          //   type: "line",
          // };
          this.statusWiseTicketChartData = res.data;
          this.statusWiseTicketChartsKey++;
          bus.$emit("showLoader", false);
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.log(err);
        });
    },
    getProjectWiseActiveTicketsGraph() {
      bus.$emit("showLoader", true);
      this.$api.adminDashboard
        .getProjectWiseActiveTicketChartData()
        .then((res) => {
          let obj = res.data;
          obj.datasets[1] = {
            ...obj.datasets[1],
            type: "line",
          };
          this.projectWiseActiveTicketChartData = res.data;
          this.projectWiseActiveTicketChartKey++;
          bus.$emit("showLoader", false);
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.log(err);
        });
    },
    getProjectWiseActiveAndClosedTicketGraph() {
      bus.$emit("showLoader", true);
      let params = {};

      params.start_date = this.startDate;
      params.end_date = this.endDate;

      this.$api.adminDashboard
        .getProjectWiseActiveAndClosedTicketChartData(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          let projectData = res.data;

          let projectLabels = projectData.labels;

          let projectDatesets = projectData.datasets;

          this.projectWiseActiveAndClosedTickets = {
            data: {
              labels: projectLabels,
              datasets: [
                {
                  type: "bar",
                  label: projectDatesets[0].label,
                  data: projectDatesets[0].data,
                  backgroundColor: "#2196F3",
                  barThickness: 30,
                  barPercentage: 1,
                },
                {
                  type: "bar",
                  label: projectDatesets[1].label,
                  data: projectDatesets[1].data,
                  backgroundColor: "#30328D",
                  barThickness: 30,
                  barPercentage: 1,
                },
              ],
            },

            options: {
              legend: {
                labels: {
                  usePointStyle: true,
                  boxWidth: 8,
                },
              },
              scales: {
                xAxes: [{}],
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      min: 0,
                      max: 100,
                      stepSize: 25,
                      callback: function (value, index, values) {
                        return value + " % ";
                      },
                    },
                  },
                ],
              },
            },
          };
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.log(err);
        });
    },
  },
  mounted() {
    this.getProjectList();
    this.getDashboardStatistics();
    this.getStatusWiseTicketGraph();
    this.getProjectWiseActiveTicketsGraph();
    this.getProjectWiseActiveAndClosedTicketGraph();
  },
};
</script>

export const version = "/api/v1/";

export const auth = {
  token: `${version}login/`,
  authProjectUser: `${version}auth_project_user/`,
};

//admin
export const supportUser = {
  base: `${version}user/`,
};

export const project = {
  base: `${version}project/`,
  support_user: `${version}project_support_user/`,
};

export const ticket = {
  base: `${version}ticket/`,
  ticket_comment: `${version}ticket-comment/`,
};

export const projectTicket = {
  base: `${version}project-ticket/`,
  ticket_comment: `${version}project-ticket-comment/`,
};

// Admin Dashboard
export const adminDashboard = {
  dashboardStatistics: `${version}admin_dashboard_statistics/`,
  statusWiseTickets: `${version}admin_status_wise_tickets/`,
  projectWiseActiveTickets: `${version}admin_project_wise_active_tickets/`,
  latestUpdatedTickets: `${version}admin_latest_updated_tickets/`,
  highestInteractedOpenTickets: `${version}admin_highest_interacted_open_tickets/`,
  projectWiseCreatedAndClosedTickets: `${version}admin_open_closed_tickets/`,
};

export const support_ticket = {
  base: `${version}support-ticket/`,
  ticket_comment: `${version}support-ticket-comment/`,
};

// Support User Dashboard
export const supportUserDashboard = {
  dashboardStatistics: `${version}support_user_dashboard_statistics/`,
  statusWiseTickets: `${version}support_user_status_wise_tickets/`,
  projectWiseActiveTickets: `${version}support_user_project_wise_active_tickets/`,
  latestUpdatedTickets: `${version}support_user_latest_updated_tickets/`,
  highestInteractedOpenTickets: `${version}support_user_highest_interacted_open_tickets/`,
  projectWiseCreatedAndClosedTickets: `${version}support_user_open_closed_tickets/`,
};

export const modules = {
  base: `${version}modules/`,
};

import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"80vh"},model:{value:(_vm.showUserDetailDialog),callback:function ($$v) {_vm.showUserDetailDialog=$$v},expression:"showUserDetailDialog"}},[_c(VCard,{attrs:{"rounded":"lg","elevation":"2"}},[_c(VCardTitle,{staticClass:"light_background"},[_c('span',{staticClass:"text-uppercase font-weight-bold black--text"},[_vm._v(" Support User Details ")]),_c(VSpacer),_c(VIcon,{on:{"click":function($event){_vm.showUserDetailDialog = false}}},[_vm._v("mdi-close")])],1),_c(VDivider),_c(VCardText,[_c(VCard,{staticClass:"pa-0 ma-0",attrs:{"elevation":"0"}},[_c(VRow,{staticClass:"pa-0 ma-0"},[_c(VCol,{staticClass:"pa-4",attrs:{"cols":"12"}},[_c('h4',{staticClass:"text-caption"},[_vm._v("Username")]),_c('h5',{staticClass:"text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.userDetails.username)+" ")])])],1),_c(VRow,{staticClass:"pa-0 ma-0",attrs:{"elevation":"0"}},_vm._l((_vm.getUserDetails),function(value,key,index){return _c(VCol,{key:index,staticClass:"d-flex flex-column justify-start px-4 pb-2",attrs:{"cols":"6"}},[_c('h4',{staticClass:"text-caption text-capitalize"},[_vm._v(" "+_vm._s(key.replace(/\_/g, " "))+" ")]),(key == 'active' || key == 'admin')?_c('h5',{staticClass:"text-body-2 font-weight-bold"},[(value == true)?_c(VIcon,{attrs:{"color":"green"}},[_vm._v("mdi-checkbox-marked-circle")]):_c(VIcon,{attrs:{"color":"red"}},[_vm._v("mdi-close-circle")])],1):_c('h5',{staticClass:"text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(value)+" ")])])}),1),(_vm.userDetails.projects && _vm.userDetails.projects.length)?_c(VRow,{staticClass:"pa-0 ma-0",attrs:{"elevation":"0"}},[_c(VCol,{staticClass:"pa-4",attrs:{"cols":"12"}},[_c('h4',{staticClass:"text-caption"},[_vm._v("projects")]),_vm._l((_vm.userDetails.projects),function(project){return _c('h5',{key:project,staticClass:"text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(project)+" ")])})],2)],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
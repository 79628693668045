<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        searchable
        ref="SupportUserTicketListRef"
        title="Ticket"
        :table-header="columns"
        :table-data="ticketList"
        :hasListActions="true"
        :context="context"
        :total="totalItems"
        name="TicketList"
        @getList="getTicketList"
      >
        <template #listAction>
          <v-btn
            depressed
            class="text-capitalize white--text primary"
            @click="addTicket"
          >
            Add Ticket
          </v-btn>
        </template>
        <template #leftFilterSlot>
          <v-btn
            x-small
            fab
            depressed
            :color="Object.keys(filters).length == 0 ? 'primary' : 'green'"
            class="ma-1 rounded-lg"
            @click="showTicketFilter = true"
          >
            <v-icon color="white"> mdi-filter</v-icon>
          </v-btn>
        </template>
        <template #dialogs>
          <TicketFilter
            v-model="showTicketFilter"
            @applyFilters="refreshList"
          ></TicketFilter>
          <TicketForm
            ref="TicketForm"
            v-model="showTicketForm"
            @updateList="updateList"
          />
        </template>
      </BaseListLayout>
    </v-col>
  </v-row>
</template>
  
  <script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import TicketForm from "./TicketForm.vue";
import ActionButton from "@/components/AgGridButtons/Ticket/ActionButton";
import PriorityButton from "@/components/AgGridButtons/Ticket/PriorityButton";
import ActualPriority from "@/components/AgGridButtons/Ticket/ActualPriority";
import StatusButton from "@/components/AgGridButtons/Ticket/StatusButton";
import TicketDesc from "@/components/AgGridButtons/Ticket/TicketDesc";
import TicketTitle from "@/components/AgGridButtons/Ticket/TicketTitle";
import TicketFilter from "./TicketFilter.vue";
import BaseDatePickerInput from "@/components/BaseComponents/BaseDatePickerInput.vue";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import { bus } from "@/main";

export default {
  name: "SupportUserTicket",
  components: {
    BaseListLayout,
    TicketForm,
    StatusButton,
    ActionButton,
    PriorityButton,
    ActualPriority,
    TicketFilter,
    BaseDatePickerInput,
    BaseSelect,
    TicketTitle,
    TicketDesc,
  },
  data() {
    return {
      canEdit: true,
      editMode: false,
      totalItems: 0,
      ticketId: null,
      filters: {},
      ticketList: [],
      projectList: [],
      showTicketForm: false,
      showTicketFilter: false,
      columns: [
        {
          headerName: "Ticket Reference Number",
          field: "ticket_reference_number",
        },
        { headerName: "Title", field: "title", cellRenderer: "TicketTitle" },
        { headerName: "Project", field: "project_name" },
        { headerName: "Added By", field: "added_by_fullname" },
        {
          headerName: "Status",
          field: "status",
          cellRenderer: "StatusButton",
          minWidth: 100,
        },
        {
          headerName: "Actual Priority",
          field: "actual_priority",
          cellRenderer: "ActualPriority",
          minWidth: 100,
        },
        { headerName: "Created At", field: "created" },
        {
          headerName: "Actions",
          field: "actions",
          minWidth: 100,
          cellRenderer: "ActionButton",
        },
      ],
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    setFilter(field, value) {
      let preFil = localStorage.getItem("Support-Ticket-Filters");
      let selectedFilters = preFil ? JSON.parse(preFil) : {};
      if (field == "start_date") {
        value
          ? (selectedFilters.start_date = value)
          : delete selectedFilters.start_date;
      } else if (field == "end_date") {
        value
          ? (selectedFilters.end_date = value)
          : delete selectedFilters.end_date;
      } else if (field == "project") {
        value
          ? (selectedFilters.project = value)
          : delete selectedFilters.project;
      }

      localStorage.setItem(
        "Support-Ticket-Filters",
        JSON.stringify(selectedFilters)
      );
      if (!Object.values(selectedFilters).length) {
        localStorage.removeItem("Support-Ticket-Filters");
      }
      this.refreshList();
    },
    getProjectList() {
      this.$api.project
        .getProjectList({ limit: "all" })
        .then((res) => {
          this.projectList = res.data;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    getTicketList(params) {
      bus.$emit("showLoader", true);
      let filters = localStorage.getItem("Support-Ticket-Filters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      this.filters = filters;

      this.$api.supportTicket
        .getTicketList({ ...filters, ...params })
        .then((res) => {
          this.totalItems = res.count;
          this.ticketList = res.data.results;
          bus.$emit("showLoader", false);
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    refreshList() {
      this.$refs.SupportUserTicketListRef.refreshList();
    },
    updateList() {
      this.$refs.SupportUserTicketListRef.updateList();
    },
    addTicket() {
      this.editMode = false;
      this.showTicketForm = true;
    },
    viewDetails(id) {
      this.$router.push(`/support-user/ticket-details?id=${id}`);
    },
  },
  mounted() {
    this.getProjectList();
  },
};
</script>
  
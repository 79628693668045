<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        searchable
        ref="ProjectListRef"
        title="Project"
        :table-header="columns"
        :table-data="projectList"
        :hasListActions="true"
        :context="context"
        :total="totalItems"
        name="ProjectList"
        @getList="getProjectList"
      >
        <template #leftFilterSlot>
          <v-btn
            x-small
            fab
            depressed
            :color="Object.keys(filters).length == 0 ? 'primary' : 'green'"
            class="ma-1 rounded-lg"
            @click="showProjectFilter = true"
          >
            <v-icon color="white"> mdi-filter</v-icon>
          </v-btn>
        </template>
        <template #dialogs>
          <ProjectFilter
            v-model="showProjectFilter"
            @applyFilters="refreshList"
          ></ProjectFilter>
        </template>
      </BaseListLayout>
    </v-col>
  </v-row>
</template>
  
<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import ProjectActionButton from "@/components/AgGridButtons/ProjectActionButton.vue";
import ActiveButton from "@/components/AgGridButtons/ActiveButton.vue";
import ProjectFilter from "./ProjectFilter.vue";
import { getUserDetailsLocalStorage } from "@/utils/functions";
import { bus } from "@/main";

export default {
  name: "SupportUserProject",
  components: {
    BaseListLayout,
    ProjectActionButton,
    ActiveButton,
    ProjectFilter,
  },
  data() {
    return {
      canEdit: true,
      totalItems: 0,
      filters: {},
      projectList: [],
      showProjectFilter: false,
      columns: [
        { headerName: "Project Code", field: "code" },
        { headerName: "Project Name", field: "name" },
        { headerName: "Created At", field: "created" },
        { headerName: "Email ID", field: "email" },
        { headerName: "Contact Number", field: "contact number" },
        { headerName: "Reference Prefix", field: "reference_prefix" },
        {
          headerName: "Active",
          field: "is_active",
          cellRenderer: "ActiveButton",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "ProjectActionButton",
        },
      ],
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getProjectList(params) {
      bus.$emit("showLoader", true);
      let newParams = {
        support_user: getUserDetailsLocalStorage().user.id,
        ...params,
      };
      let filters = localStorage.getItem("Support-Project-Filters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }

      if ("search" in filters) {
        filters.search = filters.search.toString();
      }
      this.filters = filters;

      this.$api.project
        .getProjectList({ ...filters, ...newParams })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.projectList = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    refreshList() {
      this.$refs.ProjectListRef.refreshList();
    },
    projectSettings(id) {
      this.$router.push(`/support-user/project-settings?id=${id}`);
    },
  },
};
</script>
  
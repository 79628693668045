<template>
  <v-navigation-drawer
    permanent
    width="270"
    class=" navigation-shadow"
    style="z-index: 11 !important"
    clipped
    app
    color="white"
    mini-variant
    expand-on-hover
  >
    <v-list dense>
      <v-list-item v-for="(item, i) in menu" :key="i" class="pa-0 ma-0">
        <v-list-group
          v-if="item && item.items && item.items.length > 0"
          class="w-100 v-list-sub-group black--text"
        >
          <template v-slot:activator>
            <v-list-item router exact :to="item.to">
              <v-list-item-action class="mr-2">
                <v-icon class="text-h6 font-weight-light" color="black">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="text-transform: capitalize">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            class="v-list-sub-group"
            router
            exact
            v-for="(subItem, index) in item.items"
            :key="index"
            :to="subItem.to"
          >
            <v-list-item-action
              v-if="subItem.title == 'Updates By AWB'"
              class="mr-2"
            >
              <span class="text-h6 black--text rounded-lg text-caption">
                AWB
              </span>
            </v-list-item-action>
            <v-list-item-action v-else class="mr-2">
              <v-icon class="text-h6 font-weight-light " color="black">
                {{ subItem.icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title style="text-transform: capitalize">
                {{ subItem.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item v-else :to="item.to" router exact>
          <v-list-item-action class="mr-2">
            <v-icon class="text-h6 font-weight-light">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title style="text-transform: capitalize">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { getUserDetailsLocalStorage } from "@/utils/functions";

export default {
  data() {
    return {
      menu: [],
      adminMenu: [
        {
          icon: "mdi-view-dashboard",
          title: "Dashboard",
          to: "dashboard",
        },
        {
          icon: "mdi-view-dashboard-variant",
          title: "Stats Center",
          to: "statsCenter",
        },
        {
          icon: "mdi-account-supervisor",
          title: "Support User",
          to: "supportuser",
        },
        {
          icon: "mdi-rotate-orbit",
          title: "Project",
          to: "project",
        },
        {
          icon: "mdi-ticket",
          title: "Ticket",
          to: "ticket",
        },
      ],
      projectUser: [
        {
          icon: "mdi-ticket",
          title: "Ticket",
          to: "ticket",
        },
      ],
      supportUser: [
        {
          icon: "mdi-view-dashboard",
          title: "Dashboard",
          to: "dashboard",
        },
        {
          icon: "mdi-rotate-orbit",
          title: "Project",
          to: "project",
        },
        {
          icon: "mdi-ticket",
          title: "Ticket",
          to: "ticket",
        },
      ],
    };
  },
  methods: {
    loadNavMenu() {
      let userData = getUserDetailsLocalStorage().user;
      let interval = setInterval(() => {
        if (userData) {
          clearInterval(interval);
          if (userData.is_admin) {
            this.menu = this.adminMenu;
          } else if (!userData.is_admin && userData.type == "Project User") {
            this.menu = this.projectUser;
          } else if (!userData.is_admin && userData.type == "Support User") {
            this.menu = this.supportUser;
          }
        }
      });
    },
  },
  mounted() {
    this.loadNavMenu();
  },
};
</script>

<style lang="scss">
.v-list-sub-group {
  .v-list-item-title {
    font-weight: 450;
    font-size: 15px;
  }

  .v-list-item__prepend,
  .v-list-item__append {
    .v-icon {
      opacity: 1;
    }
  }
}
</style>

<template>
  <v-dialog width="80vh" v-model="showUserDetailDialog">
    <v-card rounded="lg" elevation="2">
      <v-card-title class="light_background">
        <span class="text-uppercase font-weight-bold black--text">
          Support User Details
        </span>
        <v-spacer></v-spacer>
        <v-icon @click="showUserDetailDialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-card class="pa-0 ma-0" elevation="0">
          <v-row class="pa-0 ma-0">
            <v-col cols="12" class="pa-4">
              <h4 class="text-caption">Username</h4>
              <h5 class="text-body-2 font-weight-bold">
                {{ userDetails.username }}
              </h5>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0" elevation="0">
            <v-col
              cols="6"
              v-for="(value, key, index) in getUserDetails"
              :key="index"
              class="d-flex flex-column justify-start px-4 pb-2"
            >
              <h4 class="text-caption text-capitalize">
                {{ key.replace(/\_/g, " ") }}
              </h4>
              <h5
                v-if="key == 'active' || key == 'admin'"
                class="text-body-2 font-weight-bold"
              >
                <v-icon v-if="value == true" color="green"
                  >mdi-checkbox-marked-circle</v-icon
                >
                <v-icon v-else color="red">mdi-close-circle</v-icon>
              </h5>
              <h5 v-else class="text-body-2 font-weight-bold">
                {{ value }}
              </h5>
            </v-col>
          </v-row>
          <v-row
            v-if="userDetails.projects && userDetails.projects.length"
            class="pa-0 ma-0"
            elevation="0"
          >
            <v-col cols="12" class="pa-4">
              <h4 class="text-caption">projects</h4>
              <h5
                v-for="project in userDetails.projects"
                :key="project"
                class="text-body-2 font-weight-bold"
              >
                {{ project }}
              </h5>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
  
  <script>
import { bus } from "@/main";

export default {
  name: "UserDetails",
  props: {
    value: Boolean,
    userDetails: Object,
  },
  data() {
    return {};
  },

  computed: {
    context() {
      return { parentComponent: this };
    },
    showUserDetailDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    getUserDetails() {
      return {
        first_name: this.userDetails.first_name,
        last_name: this.userDetails.last_name,
        contact_number: this.userDetails.contact_number,
        email: this.userDetails.email,
        admin: this.userDetails.is_admin,
        active: this.userDetails.is_active,
      };
    },
  },
  methods: {},
};
</script>
  
<template>
  <div style="background-color: #ececef">
    <v-row class="mx-0 d-flex align-center">
      <v-col cols="auto">
        <v-btn
          class="rounded-lg"
          style="background-color: #ececef"
          fab
          depressed
          small
          @click="$router.back()"
        >
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span class="text-h6" v-bind="attrs" v-on="on">
              {{ ticketDetails.ticket_reference_number }} -
            </span>
          </template>
          <span class="text-capitalize">Ticket Reference Number</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span class="text-h6" v-bind="attrs" v-on="on">
              {{ ticketDetails.project_name }}
            </span>
          </template>
          <span class="text-capitalize">Project Name</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <div class="px-3 pb-3 main-layout">
      <div class="support-column-grid pt-1">
        <div class="d-flex flex-column">
          <v-card class="mb-2" elevation="0">
            <v-card-title class="px-3 py-1">
              <v-row no-gutters>
                <v-col cols="8">
                  <h5>Basic Details</h5>
                </v-col>
                <v-col cols="4" class="d-flex justify-end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <h5 v-bind="attrs" v-on="on" class="text-caption">
                        <v-chip class="ml-2 font-weight-black" color="success">
                          {{ ticketDetails.status }}
                        </v-chip>
                      </h5>
                    </template>
                    <span class="text-capitalize">Status</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-subtitle
              style="height: calc(100vh - 600px)"
              class="overflow-y-auto custom-small-scroll"
            >
              <v-row no-gutters class="px-2">
                <v-col cols="12">
                  <h3>Title</h3>
                  <h4 class="font-weight-black primary--text">
                    {{ ticketDetails.title }}
                  </h4>
                </v-col>
                <v-col cols="12" class="pt-3">
                  <h3>Description</h3>
                  <h4 class="primary--text">
                    {{ ticketDetails.description }}
                  </h4>
                </v-col>
              </v-row>
            </v-card-subtitle>
          </v-card>
          <v-card
            class="my-2 overflow-y-auto app-relative custom-small-scroll"
            style="height: 400px"
            elevation="0"
          >
            <v-card-title class="px-3 py-1 sticky-top white d-flex flex-column">
              <v-row no-gutters class="w-100">
                <v-col cols="6">
                  <h5>Other Details</h5>
                </v-col>
                <v-col cols="6" class="text-right">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <h5 v-bind="attrs" v-on="on" class="text-caption">
                        <v-chip color="success">
                          <span class="text-capitalize font-weight-black">
                            {{ ticketDetails.priority }}
                          </span>
                        </v-chip>
                      </h5>
                    </template>
                    <span class="text-capitalize">Priority</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider class="w-100"></v-divider>
            <v-card-subtitle class="pt-4">
              <div>
                <div>
                  <v-row no-gutters class="pt-1 px-2">
                    <v-col cols="5">
                      <h4>Added By</h4>
                    </v-col>
                    <v-col cols="7" class="d-flex justify-end primary--text">
                      <h4>{{ ticketDetails.added_by_name }}</h4>
                    </v-col>
                    <v-col cols="5">
                      <h4>Actual Priority</h4>
                    </v-col>
                    <v-col
                      cols="7"
                      class="d-flex justify-end primary--text text-capitalize"
                    >
                      <h4>{{ ticketDetails.actual_priority }}</h4>
                    </v-col>
                    <v-col cols="5">
                      <h4>Assignee</h4>
                    </v-col>
                    <v-col cols="7" class="d-flex justify-end primary--text">
                      <h4>{{ ticketDetails.assignee_name }}</h4>
                    </v-col>
                    <v-col cols="5">
                      <h4>Due Date</h4>
                    </v-col>
                    <v-col cols="7" class="d-flex justify-end primary--text">
                      <h4>{{ ticketDetails.due_date }}</h4>
                    </v-col>
                    <v-col cols="5">
                      <h4>Project</h4>
                    </v-col>
                    <v-col cols="7" class="d-flex justify-end primary--text">
                      <h4>{{ ticketDetails.project_name }}</h4>
                    </v-col>
                    <v-col cols="5">
                      <h4>Module</h4>
                    </v-col>
                    <v-col cols="7" class="d-flex justify-end primary--text">
                      <h4>{{ ticketDetails.module }}</h4>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-card-subtitle>
          </v-card>
        </div>
        <v-card class="mb-3 mx-2" elevation="0">
          <v-card-title class="px-3 py-1">
            <v-row no-gutters>
              <v-col cols="6">
                <h5>Comments</h5>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="d-flex flex-column">
            <div
              id="comment-target"
              class="overflow-y-auto custom-small-scroll bg-green"
              style="height: calc(100vh - 380px)"
            >
              <div>
                <div
                  v-for="(chat, index) in ticketDetails.ticket_comments"
                  :key="index"
                  class="d-flex py-2 px-8"
                  :class="!chat.set_right ? 'justify-start' : 'justify-end'"
                >
                  <div
                    class="w-80 pa-2 rounded-lg grey lighten-3"
                    style="display: grid; grid-template-columns: 50px 1fr"
                  >
                    <div class="d-flex justify-start mx-0 px-0">
                      <v-avatar
                        :color="!chat.set_right ? 'cyan' : 'warning'"
                        class="white--text font-weight-bold text-uppercase text-caption"
                        size="25"
                      >
                        {{
                          chat.added_by_fullname
                            ? chat.added_by_fullname.slice(0, 2)
                            : ""
                        }}</v-avatar
                      >
                      <!-- <v-btn
                        depressed
                        class="mr-1"
                        fab
                        x-small
                        :color="!chat.set_right ? 'cyan' : 'warning'"
                      >
                        {{
                          chat.added_by_fullname
                            ? chat.added_by_fullname.slice(0, 2)
                            : ""
                        }}
                      </v-btn> -->
                    </div>
                    <div class="mx-0 px-0">
                      <div class="d-flex justify-space-between pr-2">
                        <h5 class="font-weight-black mr-2">
                          {{ chat.added_by_fullname }}
                        </h5>
                        <h5 class="font-weight-light">
                          {{ chat.created }}
                        </h5>
                      </div>
                      <div
                        class="d-flex flex-column text-right"
                        v-if="chat.attachment"
                      >
                        <a :href="chat.attachment" target="_blank"
                          >{{ chat.attachment }}
                        </a>
                      </div>
                      <div class="d-flex" v-else>
                        {{ chat.comment }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="px-4 pt-4">
              <v-row no-gutters>
                <v-col cols="1">
                  <v-btn
                    depressed
                    class="mr-1"
                    fab
                    dark
                    x-small
                    color="primary"
                  >
                    {{
                      ticketDetails.added_by_fullname
                        ? ticketDetails.added_by_fullname.slice(0, 2)
                        : ""
                    }}
                  </v-btn>
                </v-col>
                <v-col cols="11">
                  <v-form ref="commentForm" v-model="isValid">
                    <v-textarea
                      height="100"
                      outlined
                      hide-details="auto"
                      label="Comment here..."
                      v-model="ticket.comment"
                    ></v-textarea>
                  </v-form>
                </v-col>
                <v-col cols="12" class="text-right pt-3 pr-1">
                  <v-file-input
                    append-icon="mdi-paperclip"
                    prepend-icon=""
                    ref="fileUpload"
                    v-model="ticket.attachment"
                    label="File input"
                    class="mr-2 d-none"
                    outlined
                    dense
                  >
                  </v-file-input>
                  <v-icon
                    color="primary"
                    class="mr-4"
                    @click="$refs.fileUpload.$refs.input.click()"
                    >mdi-paperclip</v-icon
                  >
                  <v-btn
                    color="primary"
                    small
                    :disabled="
                      ticket.comment == null && ticket.attachment == null
                    "
                    depressed
                    @click="sendComment"
                  >
                    send
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
        <div class="d-flex flex-column">
          <v-card
            class="mb-2 overflow-y-auto app-relative custom-small-scroll"
            elevation="0"
          >
            <v-card-title class="px-3 py-1">
              <v-row no-gutters>
                <v-col cols="6">
                  <h5>Update</h5>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row class="d-flex align-center">
                <v-col cols="10">
                  <BaseSelect
                    :isClearable="false"
                    :isSearchRequired="false"
                    label="Actual Priority"
                    :itemsList="priorityOption"
                    item-text="label"
                    item-value="value"
                    v-model="actual_priority"
                  />
                </v-col>
                <v-btn
                  v-if="actual_priority != ticketDetails.actual_priority"
                  class="rounded-lg primary"
                  depressed
                  x-small
                  @click="updatePriority()"
                >
                  Update
                </v-btn>
                <v-col cols="10" v-if="ticketDetails.status != 'Closed'">
                  <BaseSelect
                    :isClearable="false"
                    :isSearchRequired="false"
                    label="Status"
                    :itemsList="statusOption"
                    v-model="status"
                  />
                </v-col>
                <v-btn
                  v-if="status != ticketDetails.status"
                  class="rounded-lg primary"
                  depressed
                  x-small
                  @click="updateStatus(status)"
                >
                  Update
                </v-btn>
                <v-col cols="10" v-if="ticketDetails.status == 'Closed'">
                  <v-btn
                    class="rounded-lg primary"
                    depressed
                    x-small
                    @click="updateStatus('Reopen')"
                  >
                    Reopen
                  </v-btn>
                </v-col>
                <v-col cols="10">
                  <BaseDatePickerInput
                    v-model="due_date"
                    id="due_date"
                    name="due_date"
                    label="Due Date"
                    clearable
                  >
                  </BaseDatePickerInput>
                </v-col>
                <v-btn
                  v-if="due_date != ticketDetails.due_date"
                  class="rounded-lg primary"
                  depressed
                  x-small
                  @click="updateDueDate()"
                >
                  Update
                </v-btn>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card class="mb-3" elevation="0">
            <v-card-title class="px-3 py-1">
              <v-row no-gutters>
                <v-col cols="6">
                  <h5>Logs</h5>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="overflow-y-auto" style="height: 580px">
              <v-row no-gutters>
                <v-row no-gutters v-if="ticketDetails.ticket_logs">
                  <v-col cols="12" class="pr-4">
                    <v-timeline dense>
                      <v-timeline-item
                        v-for="(log, i) in ticketDetails.ticket_logs"
                        :key="i"
                        small
                      >
                        <template v-slot:opposite> </template>
                        <div>
                          <v-row no-gutters>
                            <v-col cols="12" class="secondary--text">
                              {{ log.message }}
                            </v-col>
                            <v-col cols="6" class="text-caption">
                              {{ log.added_by_fullname }}
                            </v-col>
                            <v-col
                              cols="6"
                              class="text-caption text-right font-weight-light text_color--text"
                            >
                              <div style="font-size: 10px">
                                {{ log.created }}
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-timeline-item>
                    </v-timeline>
                  </v-col>
                </v-row>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- <v-card class="my-3 h-100" elevation="0">
            <v-card-title class="px-3 py-1">
              <v-row no-gutters>
                <v-col cols="12">
                  <h6>Attachments</h6>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-subtitle>
              <v-row no-gutters>
                <v-col
                  cols="3"
                  class="pa-1"
                  v-for="(document, index) in ticketDetails.ticket_comments"
                  :key="index"
                >
                  <v-card
                    v-if="document.attachment"
                    elevation="0"
                    class="pa-1"
                    outlined
                  >
                    <a
                      :href="document.attachment"
                      target="_blank"
                      style="text-decoration: none"
                    >
                      <v-img :src="document.attachment" height="50"></v-img>
                    </a>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-subtitle>
          </v-card> -->
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
/* eslint-disable vue/no-unused-components */
import { bus } from "@/main.js";
import { generatedFormData } from "@/utils/functions.js";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import BaseDatePickerInput from "@/components/BaseComponents/BaseDatePickerInput.vue";

export default {
  components: { BaseSelect, BaseDatePickerInput },
  data() {
    return {
      isValid: true,
      ticketID: null,
      ticket: {},
      ticketDetails: {},
      status: null,
      actual_priority: null,
      due_date: null,
      priorityOption: [
        { label: "Low", value: "low" },
        { label: "Medium", value: "medium" },
        { label: "High", value: "high" },
      ],
      statusOption: [
        "Open",
        "Acknowledged",
        "In Progress",
        "Resolved",
        "Closed",
      ],
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getTicketObject(id) {
      bus.$emit("showLoader", true);
      this.$api.ticket
        .getTicketObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.ticketDetails = res.data;
          this.status = this.ticketDetails.status;
          this.actual_priority = this.ticketDetails.actual_priority;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.log(err);
        });
    },
    sendComment() {
      bus.$emit("showLoader", true);
      if (this.ticket.comment || this.ticket.attachment) {
        let payload = {
          ticket: this.ticketID,
          ...this.ticket,
        };
        this.$api.ticket
          .sendComment(generatedFormData(payload))
          .then((res) => {
            bus.$emit("showLoader", false);
            this.ticket = {};
            this.getTicketObject(this.ticketID);
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            console.log(err);
          });
      }
    },
    updateStatus(statusToUpdate) {
      bus.$emit("showLoader", true);
      this.$api.ticket
        .updateStatus(this.ticketID, {
          status: statusToUpdate == "Reopen" ? "Reopen" : statusToUpdate,
        })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.ticket = {};
          bus.$emit("showToastMessage", {
            message: "Status Changed Successfully",
            color: "success",
          });
          this.getTicketObject(this.ticketID);
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.log(err);
        });
    },
    updatePriority() {
      bus.$emit("showLoader", true);
      this.$api.ticket
        .updatePriority(this.ticketID, { priority: this.actual_priority })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.ticket = {};
          bus.$emit("showToastMessage", {
            message: "Priority Changed Successfully",
            color: "success",
          });
          this.getTicketObject(this.ticketID);
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.log(err);
        });
    },
    updateDueDate() {
      bus.$emit("showLoader", true);
      this.$api.ticket
        .updateDuedate(this.ticketID, { due_date: this.due_date })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.ticket = {};
          bus.$emit("showToastMessage", {
            message: "Due date Changed Successfully",
            color: "success",
          });
          this.getTicketObject(this.ticketID);
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.log(err);
        });
    },
  },
  mounted() {
    if ("id" in this.$route.query) {
      this.ticketID = this.$route.query.id;
      this.getTicketObject(this.ticketID);
    }
  },
};
</script>
  
  <style scoped>
.main-layout {
  background-color: #ececef;
  height: 100%;
}
.main-layout .support-column-grid {
  display: grid;
  grid-template-columns: 400px 1fr 400px;
  grid-column-gap: 8px;
  height: calc(100vh - 130px);
}

.card-vehicle-analytic-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 9px;
}
.w-80 {
  max-width: 80% !important;
  width: auto !important;
}
</style>
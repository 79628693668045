<template>
  <v-app-bar :clipped-left="true" :clipped-right="true" fixed app>
    <v-toolbar-title class="d-flex">
      <slot name="appBarLogo"></slot>

      <h4 v-if="title" :class="customClass">{{ title }}</h4>
    </v-toolbar-title>
    <v-spacer />
    <div class="mr-6">
      <slot name="userNotifications" />
    </div>

    

    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="150"
      min-width="100px"
      offset-y
      transition="slide-y-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-avatar height="90%">
            <slot name="profileImage" class="profile_image" />
          </v-avatar>
          <span class="primary--text font-weight-bold pl-3">
            <slot name="userName" />
          </span>
        </div>
      </template>
      <v-card elevation="0">
        <v-card-text class="pa-0">
          <slot name="listItemsMenu" />
        </v-card-text>
      </v-card>
    </v-menu>

    <slot name="dialogs"> </slot>
  </v-app-bar>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable */

export default {
  name: "baseAppBar",
  props: {
    title: {
      type: String,
    },
    customClass: {
      type: String,
    },
  },
  data() {
    return {
      menu: false,
      openNotifications: false,
    };
  },
};
</script>

<style scoped>
.profile_image {
  width: 60% !important;
}
</style>

<template>
  <v-row>
    <v-col>
      <v-chip
        :color="color"
        small
        class="text-capitalize font-weight-bold text-white"
      >
        {{ params.data.status }}
      </v-chip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  computed: {
    color() {
      switch (this.params.data.status) {
        case "Open":
          return "#FF9800";
        case "In Progress":
          return "#5B6E80";
        case "Resolved":
          return "#9C27B0";
        case "Reopen":
          return "#2196F3";
        case "Closed":
          return "#4CAF50";
        case "Cancelled":
          return "#262626";
      }
    },
  },
};
</script>
  
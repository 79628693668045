const TicketStatus = {
  OPEN: "Open",
  INPROGRESS: "In Progress",
  ACKNOWLEDGED: "Acknowledged",
  RESOLVED: "Resolved",
  REOPENED: "Reopened",
  CLOSED: "Closed",
};
const TicketTypeList = [
  {
    text: "Feature Request",
    value: "feature_request",
  },
  {
    text: "Problem/Issue",
    value: "issue",
  },
  {
    text: "Feedback",
    value: "feedback",
  },
  {
    text: "Question",
    value: "question",
  },
  {
    text: "Others",
    value: "others",
  },
];

export default {
  TicketStatus,
  TicketTypeList,
};

<template>
  <v-row>
    <v-col cols="12">
      <v-btn
        class="rounded-lg mr-2"
        x-small
        fab
        depressed
        @click="viewDetails()"
      >
        <v-icon color="primary">mdi-eye</v-icon>
      </v-btn>
      <v-btn
        class="rounded-lg mr-2"
        x-small
        fab
        depressed
        @click="editDetails()"
      >
        <v-icon color="green">mdi-pencil</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    editDetails() {
      this.params.context.parentComponent.editDetails(this.params.data.id);
    },
    viewDetails() {
      this.params.context.parentComponent.viewDetails(this.params.data.id);
    },
  },
};
</script>

<style>
</style>